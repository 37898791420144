import { Animated, View, StyleSheet, Easing, TouchableOpacity, Pressable, Text } from 'react-native'
import React, { useRef, useState } from 'react'
import { AntDesign } from '@expo/vector-icons'

const CustomActionButton = ({ colorScheme, onPress, actionButtonItems }: { colorScheme: 'dark' | 'light', actionButtonItems: any[], onPress?: Function }) => {
    const spinValue = useRef(new Animated.Value(0)).current;
    const expandedValue = useRef(new Animated.Value(0)).current;
    const posValue = useRef(new Animated.Value(0)).current;
    let isExpanded = false;
    actionButtonItems = actionButtonItems.filter(x => x);
    actionButtonItems.forEach(actionButton => {
        const basePos = ((actionButtonItems.length - (actionButtonItems.indexOf(actionButton))) * 70);
        actionButton.posValue = posValue.interpolate({
            inputRange: [0, 1],
            outputRange: [basePos, 0]
        })
    });
    
    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '45deg']
    })
    const opacity = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0.7]
    })
    
    const translateX = expandedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [1000, 0]
    })

    const buttonPressed = () => {     
        onPress && onPress();
        animate();
        isExpanded = !isExpanded;
    }
    const animate = () => {
        Animated.timing(spinValue,
            {
                toValue: isExpanded ? 0 : 1,
                duration: 200,
                easing: Easing.ease,
                useNativeDriver: true
            }
        ).start()
        Animated.timing(expandedValue, {
            toValue: isExpanded ? 0 : 1,
            duration: 0,
            easing: Easing.ease,
            useNativeDriver: true
        }).start();
        actionButtonItems.forEach(x => {
            if(!x.posValue){
                return;
            }
            Animated.timing(posValue,
                {
                    toValue: isExpanded ? 0 : 1,
                    duration: isExpanded ? Math.abs(x.basePosition) * (4 / actionButtonItems.length) : 400,
                    easing: Easing.ease,
                    useNativeDriver: true
                }
            ).start()
        })
    }

    const actionButtonItem = (actionButton: ICustomActionButtonItem) => {
        
        return (
            <Animated.View style={actionButton.posValue && { transform: [{ translateY: actionButton.posValue }], flexDirection: 'row' }} key={actionButtonItems.indexOf(actionButton)}>
                <View style={{alignSelf: 'center', marginHorizontal: 10, backgroundColor: 'white', padding: 2, borderRadius: 3}}>
                    <Text>{actionButton.title}</Text>
                </View>
                <TouchableOpacity style={[{ backgroundColor: actionButton.color }, styles.actionButton]} onPress={()=> {buttonPressed(); actionButton.onPress()}} activeOpacity={0.6}>
                    <View style={styles.icon} >
                        {actionButton.icon}
                    </View>
                </TouchableOpacity>
            </Animated.View>);
    }
    return (
        <Animated.View style={{ width: '100%', position: 'absolute', alignItems: 'flex-end', flexDirection: 'row', bottom: 0, height: '100%'  }} pointerEvents='box-none'>
            <Animated.View style={{transform: [{translateX: translateX}], position: 'absolute', width: '100%', height: '100%', opacity: opacity}} pointerEvents='box-none'>
                <Pressable style={{position: 'absolute', backgroundColor: '#111', width: '100%', height: '100%'}} onPress={()=> {isExpanded && buttonPressed()}}/>
            </Animated.View>
            <View style={styles.container} pointerEvents='box-none'>
                <View style={{ flexGrow: 0, overflow: 'hidden', alignItems: 'flex-end'}} pointerEvents='box-none'>
                    {actionButtonItems.map(item => actionButtonItem(item))}
                </View>
                <TouchableOpacity style={[colorScheme == 'dark' ? styles.containerDark : styles.containerLight, styles.actionButton]} onPress={buttonPressed} activeOpacity={0.6}>
                    <View style={styles.icon}>
                        <Animated.View style={{ transform: [{ rotate: spin }] }}>
                            <AntDesign name='plus' color={colorScheme == 'dark' ? '#ddd' : '#333'} size={20} />
                        </Animated.View>
                    </View>
                </TouchableOpacity>
            </View>
        </Animated.View>
  )
}
const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        padding: '5%'
    },
    actionButton: {
        justifyContent: 'center',
        width: 60,
        height: 60,
        borderRadius: 30,
        marginVertical: 5
    },
    containerLight: {
      backgroundColor: '#fff',
    },
    containerDark: {
      backgroundColor: '#333',
    },
    icon:{
        alignSelf: 'center',
    },
    actionButtonItem: {
        alignSelf: 'baseline'
    }
})

interface ICustomActionButtonItem{
    title: string,
    icon: JSX.Element,
    onPress: Function,
    color: string,
    posValue?: Animated.Value
}

export default CustomActionButton
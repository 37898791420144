/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme, Theme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Pressable } from 'react-native';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import AlertDetailScreen from '../screens/AlertDetailScreen';
import AlertsScreen from '../screens/AlertsScreen';
import DeviceAlertsScreen from '../screens/DeviceAlertsScreen';
import DeviceDetailScreen from '../screens/DeviceDetailScreen';
import DeviceSignalRScreen from '../screens/DeviceSignalRScreen';
import DevicesOverviewScreen from '../screens/DevicesOverviewScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import SignInScreen from '../screens/SignInScreen';
import { RootStackParamList, RootTabParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { EventRegister } from 'react-native-event-listeners';
import TwoFactorConfirmationScreen from '../screens/TwoFactorConfirmationScreen';
import DeviceAntivirusThreatsScreen from '../screens/DeviceAntivirusThreatsScreen';
import NavigationControl from './NavigationControl';
import AboutScreen from '../screens/about/AboutScreen';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const darkTheme: Theme = {
    colors: {
      background: "#121212",
      primary: "#0a84ff",
      card: '#121212',
      notification: '#ff453a',
      text: '#e5e5e7',
      border: "#272729"
    },
    dark: true
  };
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? darkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false}}>
      <Stack.Screen name="SignIn" component={SignInScreen}  />
      <Stack.Screen name="Root" component={NavigationControl} options={{ headerShown: false }}/>
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal', }} >
        <Stack.Screen name="AlertDetail" component={AlertDetailScreen} />
        <Stack.Screen name="DeviceDetail" component={DeviceDetailScreen} />
        <Stack.Screen name="DeviceAlerts" component={DeviceAlertsScreen} />
        <Stack.Screen name="DeviceDirectNow" component={DeviceSignalRScreen} />
        <Stack.Screen name="DeviceAntivirusThreats" component={DeviceAntivirusThreatsScreen} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'containedTransparentModal', }}>
        <Stack.Screen name="TwoFactorConfirmation" component={TwoFactorConfirmationScreen} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'card', headerShown: true }}>
        <Stack.Screen name="About" component={AboutScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */


/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */

class WebServiceEnumConverter {
    static getCheckTypeString = (checkType?: number): string => {
        switch (checkType) {
            case 0:
                return "Anwendung";
            case 1:
                return "Ereignisprotokoll";
            case 2:
                return "Dateigröße";
            case 3:
                return "Festplatte";
            case 4:
                return "HTTP";
            case 5:
                return "Anmeldung-Fehlgeschlagen";
            case 6:
                return "Patches";
            case 7:
                return "Performance";
            case 8:
                return "Ping";
            case 9:
                return "Windowsdienste";
            case 10:
                return "Antivirus";
            case 11:
                return "Backup";
            case 12:
                return "HyperV";
            case 13:
                return "MS-Backup";
            case 14:
                return "PowerShell";
            case 15:
                return "SMART";
            case 17:
                return "SNMP";
            case 18:
                return "SQL";
            case 19:
                return "SSL";
            case 20:
                return "Anbieter Garantie";
            case 21:
                return "VMWare";
            case 22:
                return "Letzter Kontakt";
            case 23:
                return "SecurePoint";
            case 24:
                return "RAID Controller";
            default:
                return "unbekannte Checkart";
        }
    }
    static getIntervalString = (interval: number) : string => {
        switch (interval) {
            case 0:
                return "5 Minuten";
            case 1:
                return "10 Minuten";
            case 2:
                return "30 Minuten";
            case 3:
                return "1 Stunde";
            case 4:
                return "3 Stunden";
            case 5:
                return "6 Stunden";
            case 6:
                return "1 Minute";
            default:
                return "unbekanntes Intervall";
        }
    }
}

export default WebServiceEnumConverter;
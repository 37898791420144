import {
  View,
  Text,
  TextInput,
  StyleSheet,
  KeyboardTypeOptions,
  KeyboardAvoidingView,
  TextInputKeyPressEventData,
  NativeSyntheticEvent,
  Pressable,
  StyleProp, ViewStyle
} from 'react-native'
import React, { SetStateAction } from 'react'
import useColorScheme from '../../hooks/useColorScheme';

const CustomInput = ({ value, setValue, placeholder, secureTextEntry, clearButtonMode, keyboardType, enterPressed, style }: { value: string, setValue: React.Dispatch<SetStateAction<string>>, placeholder: string, secureTextEntry?: boolean, clearButtonMode: 'never' | 'while-editing' | 'unless-editing' | 'always' | undefined, keyboardType?: KeyboardTypeOptions, enterPressed?: Function, style?: StyleProp<ViewStyle> | undefined; }) => {
  const colorScheme = useColorScheme();
  var textInput: TextInput | null;
  return (
    <Pressable style={[styles.container, (colorScheme == 'dark' ? styles.containerDark : styles.containerLight), style]}
    onPress={()=> textInput?.focus()}
    focusable={false}>
      <View style={webStyles.border}>
      <TextInput
        ref={(data) => textInput = data }
        style={[webStyles.textInput, (colorScheme == 'dark' ? styles.inputDark : styles.inputLight)]}
        placeholder={placeholder}
        value={value}
        onChangeText={setValue}
        secureTextEntry={secureTextEntry}
        autoCorrect={false}
        autoCompleteType='off'
        placeholderTextColor='#afafaf'
        clearButtonMode={clearButtonMode}
        keyboardType={keyboardType}
        onSubmitEditing={() => { if (enterPressed) { enterPressed() } }}
      />
      </View>
    </Pressable>
  )
}
const webStyles = StyleSheet.create({
  textInput: {
    borderWidth: 0,
  },
  border: {
    borderWidth: 0,
    borderColor: 'rgba(158, 150, 150, 0)'
  },
});
const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginVertical: 10
  },
  containerLight: {
    backgroundColor: '#fff',
    borderColor: '#e8e8e8',
  },
  containerDark: {
    backgroundColor: '#1a1a1a',
    borderColor: '#3a3a3a',
  },
  inputDark: {
    color: '#fff'
  },
  inputLight: {
    color: '#000'
  }
})

export default CustomInput
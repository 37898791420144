import { View, Text, KeyboardAvoidingView, ScrollView, StyleSheet, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { JsonHubProtocol } from '@microsoft/signalr';
import CustomInput from '../components/CustomInput';
import useColorScheme from '../hooks/useColorScheme';
import WebServiceClient from '../components/util/WebServiceClient';
import DirectNowClient from '../components/util/DirectNowClient';

const DeviceSignalRScreen = (props: any) => {
    const device = props.route.params;
    const [commandLineInput, setCommandLineInput] = useState('');
    const [visibleCommandLine, setVisibleCommandLine] = useState('');
    const [connecting, setConnecting] = useState(true);
    const colorScheme = useColorScheme();
    const webServiceClient = WebServiceClient.getInstance();
    const directNowClient = DirectNowClient.getInstance();

    useEffect(() => {
        connectDirectNow();
    }, [])
    let commandLine = '';

    const newInformationCallback = (data: any) => {
        if (data.consoleOutput) {
            setConnecting(false);
            commandLine = commandLine + data.consoleOutput;
            setVisibleCommandLine(commandLine);
        }
    }

    const connectDirectNow = async () => {
        webServiceClient.getDeviceByI3D(device.DeviceI3D)
        .then((data) => directNowClient.initialize(data[0].DeviceGuid, newInformationCallback));

    }

    const sendDirectNowCommand = async () => {
        directNowClient.sendDirectNowCommand(commandLineInput);
    }

    return (
        <KeyboardAvoidingView behavior='position' style={{ padding: 25 }}>
            <ActivityIndicator style={{ position: 'absolute', height: '100%', width: '100%' }} animating={connecting} />
            <ScrollView style={{ height: '88%' }}>
                <Text style={(colorScheme == 'dark' ? styles.textDark : styles.textLight)}>{visibleCommandLine}</Text>
            </ScrollView>
            <CustomInput value={commandLineInput} setValue={setCommandLineInput} placeholder=':>' clearButtonMode='never' enterPressed={() => { sendDirectNowCommand(); setCommandLineInput('') }} />

        </KeyboardAvoidingView>
    )
}
const styles = StyleSheet.create({
    textDark: {
        color: '#f3f3f3',
    },
    textLight: {
        color: '#3a3a3a'
    },
})

export default DeviceSignalRScreen
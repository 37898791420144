/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';
import DeviceDetailScreen from "../screens/DeviceDetailScreen";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Alerts: {
            screens: {
              AlertScreen: 'alert',
            },
            path: '/MonitoringAlerts'
          },
          Devices: {
            screens: {
              DeviceOverviewScreen: 'devices',
            },
            path: '/Devices',
          },
        },
      },
      SignIn: 'signin',
      NotFound: '*',
      DeviceDetail: {
        path: '/Devices/DeviceDetails/:passedDeviceI3D',
      }
    },
  },
};

export default linking;

import { View, Text, StyleSheet, Pressable, GestureResponderEvent, TouchableHighlight } from 'react-native'
import React from 'react'
import useColorScheme from '../../hooks/useColorScheme';

const CustomButton = ({text, onPress, secondary}: {text: string, onPress: any, secondary?: boolean}) => {
  const colorScheme = useColorScheme();
  return (
    <TouchableHighlight style={[colorScheme == 'dark' ? styles.containerDark : secondary ? styles.containerLightSecondary  : styles.containerLight ,styles.container]} underlayColor= {colorScheme == 'dark' ? '#5a5a5a' : '#dadada'} onPress={onPress}>
      <Text style={[colorScheme == 'dark' ? styles.textDark : styles.textLight ,styles.text]}>{text}</Text>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        padding: 15,
        marginVertical: 5,
        alignItems: 'center',
        borderRadius: 10
    },
    text: {
        fontWeight: 'bold',
    },
    containerLight: {
      backgroundColor: '#fff',
      borderColor: '#e8e8e8',
    },
    containerLightSecondary: {
      backgroundColor: '#eee',
      borderColor: '#e8e8e8',
    },
    containerDark: {
      backgroundColor: '#3a3a3a',
      borderColor: '#5a5a5a',
    },
    textDark: {
      color: '#fff'
    },
    textLight: {
      color: '#000'
    }
})

export default CustomButton
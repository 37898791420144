import { View, StyleSheet, FlatList } from 'react-native'
import React, { useEffect, useState } from 'react'
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../types';
import WebServiceClient from '../components/util/WebServiceClient';
import CustomListItem from '../components/CustomListItem';
import CustomText from '../components/CustomText';
import useColorScheme from '../hooks/useColorScheme';
import ModalDragBar from '../components/ModalDragBar';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useActionSheet } from '@expo/react-native-action-sheet';
import * as Haptics from 'expo-haptics';

const DeviceAntivirusThreatsScreen = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'DeviceAntivirusThreats'>>();
  const webServiceClient = WebServiceClient.getInstance();
  const device = route.params;
  const [antivirusThreats, setAntivirusThreats] = useState<any[]>([]);
  const colorScheme = useColorScheme();
  const { showActionSheetWithOptions } = useActionSheet();

  const refresh = () => {
    webServiceClient.getAntivirusThreats({
      DeviceI3Ds: [device.I3D]
    }).then((threats) => setAntivirusThreats(threats.sort(function(a: any,b: any){
      return b.DetectionTime.getDateFromMicrosoftJsonFormatted() - a.DetectionTime.getDateFromMicrosoftJsonFormatted();
    })));
  }

  useEffect(()=> refresh(), [])

  const threatLongClicked = (item: any) => {
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    showActionSheetWithOptions({
        options: ["Cancel", "Aus Quarantäne freigeben", "Aus Quarantäne löschen"],
        destructiveButtonIndex: 2,
        cancelButtonIndex: 0,
        userInterfaceStyle: colorScheme
    },
        buttonIndex => {
            if (buttonIndex === 0) {
                return;
            } else if (buttonIndex === 1) {
                updateAntivirusStatus(item, 1);
            }
            else if (buttonIndex === 2) {
              updateAntivirusStatus(item, 3);
            }
        })
}

  const updateAntivirusStatus = (threat: any, task: number) => {
    threat.ThreadTask = task;
    threat.ThreadTaskStatus = 0;
    threat.LastActionTime = new Date().getMicrosoftJsonFormatted();
    webServiceClient.saveOrUpdateAntivirusThreats([threat]);
  }

  return (
    <View style={styles.root}>
      <ModalDragBar />
      <FlatList data={antivirusThreats}
      style={{width: '100%', height: '100%'}}
        keyExtractor={(item) => item.I3D}
        renderItem={({ item }) => (
          <CustomListItem colorScheme={colorScheme} onPress={()=> threatLongClicked(item)} onLongPress={()=> threatLongClicked(item)}>
            <View style={styles.containerInner}>
              <MaterialCommunityIcons name="virus"
                resizeMode='contain'
                style={styles.resultIcon}
                size={30}
                color={item.ThreadTaskStatus == 1 ? "#2ecc71" : "#f1c40f"}
              />
              <View style={{ maxWidth: '80%', flex: 1 }}>
                <CustomText colorScheme={colorScheme}>{item.ThreatName}</CustomText>
                <CustomText colorScheme={colorScheme}>{item.ProcessName}</CustomText>
                <CustomText colorScheme={colorScheme}>{item.DetectionTime.getDateFromMicrosoftJsonFormatted().toLocaleString()}</CustomText>
              </View>
            </View>
          </CustomListItem>)} />
    </View>
  )
}
const styles = StyleSheet.create({
  root: {
      alignItems: 'center',
      paddingHorizontal: '2%',
      paddingBottom: '15%'
  },
  itemContainer: {
    width: '100%'
  },
  containerInner: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      display: 'flex',
  },
  resultIcon: {
      height: '60%',
      maxWidth: 100,
      maxHeight: 100,
      alignSelf: 'center',
      marginHorizontal: 15,
  },
});

export default DeviceAntivirusThreatsScreen
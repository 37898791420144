import { View, Text, StyleSheet, Image, FlatList, KeyboardAvoidingView, Alert, ScrollView, TouchableWithoutFeedback, TouchableOpacityBase, TouchableOpacity, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import useColorScheme from '../hooks/useColorScheme'
import CustomButtonWithCheckMark from '../components/CustomButtonWithCheckMark/CustomButtonWithCheckMark'
import WebServiceClient from '../components/util/WebServiceClient'
import { RouteProp, StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { RootStackParamList } from '../types'
import CustomButton from '../components/CustomButton'
import ModalDragBar from '../components/ModalDragBar'
import { AntDesign, FontAwesome, FontAwesome5 } from '@expo/vector-icons'
import CustomActionButton from '../components/CustomActionButton'
import SetCheckResultOkScreen from './SetCheckResultOkScreen'
import CustomText from '../components/CustomText'
import WebServiceEnumConverter from '../components/util/WebServiceEnumConverter'

const AlertDetailScreen = () => {
    const route = useRoute<RouteProp<RootStackParamList, 'AlertDetail'>>();
    const alert = route.params;
    const colorScheme = useColorScheme();
    const webServiceCLient = WebServiceClient.getInstance();
    const [showSetOk, setShowSetOk] = useState(false);
    const navigation = useNavigation();


    const setOk = async () => {
        setShowSetOk(true);
    }
    const executeAllChecks = () => {
        webServiceCLient.setDeviceForImmediateExecution([alert.DeviceI3D]);
    }
    const openDeviceDetail = async () => {
        navigation.dispatch(StackActions.push("DeviceDetail", alert));
    }
    const deleteCheckResults = async () => {
        webServiceCLient.deleteMonCheckDeviceResults({
            DeviceI3Ds: [alert.DeviceI3D],
            CheckI3Ds: [alert.CheckI3D]
        });
    }
    return (
        <View style={styles.root}>
            <ModalDragBar />
            <View style={styles.innerContainer}>
                <View style={{alignItems: 'center', flexDirection: 'column', width: '100%', marginBottom: 25}}>
                    <CustomText colorScheme={colorScheme} style={{ opacity: 0.8, fontSize: 12, alignSelf: 'center' }}>{WebServiceEnumConverter.getCheckTypeString(alert.CheckType)}</CustomText>
                    <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        <FontAwesome style={{ alignSelf: 'center', marginHorizontal: 10 }}
                            size={30}
                            name={alert.CheckResult == 0 ? "check-circle" : alert.CheckResult == 1 ? "times-circle" : "warning"}
                            color={alert.CheckResult == 0 ? "#2ecc71" : alert.CheckResult == 1 ? "#e74c3c" : "#f1c40f"}
                        />
                        <CustomText colorScheme={colorScheme} style={styles.headerText}>{alert.CheckName}</CustomText>
                    </View>
                </View>
                <View style={styles.deviceDetails}>
                    <View style={styles.descriptionIconContainer}>
                        <FontAwesome name={alert.DeviceClass == 'Server' ? 'server' : 'desktop'}
                            size={20}
                            color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                            style={styles.descriptionIcon}
                        />
                    </View>
                    <CustomText colorScheme={colorScheme} style={styles.description}>{alert.DeviceName}</CustomText>
                </View>
                <View style={styles.deviceDetails}>
                    <View style={styles.descriptionIconContainer}>
                        <FontAwesome5 size={20}
                            color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                            style={styles.descriptionIcon}
                            name="users" />
                    </View>
                    <CustomText colorScheme={colorScheme} style={styles.description}>{alert.CustomerName}</CustomText>
                </View>
                <View style={styles.deviceDetails}>
                    <View style={styles.descriptionIconContainer}>
                        <AntDesign name='clockcircleo'
                            size={20}
                            color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                            style={styles.descriptionIcon}
                        />
                    </View>
                    <CustomText colorScheme={colorScheme} style={styles.description}>{alert.ExecutedAt ? alert.ExecutedAt.getDateFromMicrosoftJsonFormatted().toLocaleString() : "Noch nicht ausgeführt"}</CustomText>
                    
                <View style={{ borderRadius: 10, shadowColor: colorScheme === 'dark' ? '#333' : '#ccc', shadowOpacity: 0.3, shadowRadius: 10, backgroundColor: colorScheme === 'dark' ? '#1a1a1a' : '#fff', width: '100%', padding: 10, marginVertical: 15, flex: 1, maxHeight: '80%' }}>
                    <ScrollView nestedScrollEnabled>
                        <CustomText colorScheme={colorScheme} style={styles.descriptionText} selectable>{JSON.parse(alert.CheckOutput ?? '{}').Message?.trim() ?? "Kein Ergebnis"}</CustomText>
                    </ScrollView>
                </View>
                </View>

            </View>
            <CustomActionButton colorScheme={colorScheme}
                actionButtonItems={[
                    {
                        color: '#3498db',
                        icon: (<FontAwesome name={alert.DeviceClass == 'Server' ? 'server' : 'desktop'} style={styles.actionButtonIcon} />),
                        onPress: openDeviceDetail,
                        title: 'Gerät öffnen'
                    },
                    {
                        color: '#2ecc71',
                        icon: (<AntDesign name="check" style={styles.actionButtonIcon} />),
                        onPress: setOk,
                        title: 'Check auf Ok setzen'
                    },
                    {
                        color: '#9b59b6',
                        icon: (<AntDesign name="doubleright" style={styles.actionButtonIcon} />),
                        onPress: executeAllChecks,
                        title: 'Alle Checks sofort ausführen'
                    },
                    {
                        color: '#db3434',
                        icon: (<AntDesign name="delete" style={styles.actionButtonIcon} />),
                        onPress: deleteCheckResults,
                        title: 'Checkergebnisse löschen'
                    }]} />
            <SetCheckResultOkScreen show={showSetOk} pressCallback={() => setShowSetOk(false)} alert={alert} />
        </View>
    )
}
const styles = StyleSheet.create({
    root: {
        width: '100%',
        height: '100%',
        flex: 1
    },
    innerContainer: {
        paddingHorizontal: 25,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1
    },
    deviceDetails: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 5,
    },
    descriptionIcon: {
        alignSelf: 'center',
    },
    descriptionIconContainer: {
        width: '10%',
        alignSelf: 'center',
        marginVertical: 2,
    },
    description: {
        fontSize: 16,
        width: '90%',
        alignSelf: 'center',
    },
    headerText: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    headerIcon: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    },
    descriptionText: {
        width: '100%',
    },
    resultIcon: {
        width: 24,
        height: 24
    },
    list: {
        width: '100%',
        height: '100%'
    },
    light: {
        color: '#3a3a3a',
    },
    dark: {
        color: '#f3f3f3',
    },
    actions: {
        flex: 0,
        width: '100%',
        position: 'absolute',
        bottom: '7%',
        marginHorizontal: 25,
    },
    actionButtonIcon: {
        fontSize: 20,
        height: 22,
        color: 'white',
    },
});

export default AlertDetailScreen
import WebServiceClient from "./WebServiceClient";
import * as Notifications from 'expo-notifications';
import { NavigationProp } from "@react-navigation/native";
import { NotificationResponse } from "expo-notifications";
import * as LocalAuthentication from 'expo-local-authentication';
import { Platform } from "react-native";

class NotificationHandler {
    private static instance: NotificationHandler;
    private webServiceClient: WebServiceClient = WebServiceClient.getInstance();
    private navigation: NavigationProp<ReactNavigation.RootParamList>;

    constructor(navigation: NavigationProp<ReactNavigation.RootParamList>) {
        this.navigation = navigation;
    }

    static getInstance(navigation: NavigationProp<ReactNavigation.RootParamList>): NotificationHandler {
        NotificationHandler.instance = NotificationHandler.instance || new NotificationHandler(navigation);
        return NotificationHandler.instance;
    }

    async registerForPushNotifications() {
        if(Platform.OS == 'web'){
            return;
        }
        await Notifications.requestPermissionsAsync();
        Notifications.getExpoPushTokenAsync()
            .then((token) => console.log(token))
            .catch((error) => console.log(error.toString()));
        Notifications.setNotificationCategoryAsync("2faRequest", [{
            buttonTitle: "Zulassen",
            identifier: "approve"
        }, {
            buttonTitle: "Ablehnen",
            identifier: "deny",
        }])
        Notifications.addNotificationResponseReceivedListener((response) => {
            if (response.notification.request.content.data.mfaKey) {
                LocalAuthentication.authenticateAsync().then((data) => {
                    if (!data.success) {
                        return;
                    }
                    this.handle2faNotification(response);
                });
            } else if (response.notification.request.content.data) {
                this.navigation.navigate("AlertDetail", response.notification.request.content.data as any);
            }
        });
    }
    async handle2faNotification(response: NotificationResponse) {
        if (response.actionIdentifier === "approve") {
            this.webServiceClient.validate2fa(response.notification.request.content.data.mfaKey);
        } else if (response.actionIdentifier === "deny") {
            console.log("Abgelehnt für " + response.notification.request.content.data.mfaKey)
        } else {
            this.navigation.navigate("TwoFactorConfirmation", response.notification.request.content.data);
        }
    }

    async pushLocalNotification (title: string, body: string) {   
        await Notifications.scheduleNotificationAsync({
            content: {
                title: title,
                body: body
            },
            trigger: {
              seconds: 2,
            }
        })
    }
}

export default NotificationHandler;
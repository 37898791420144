import signalR, { HubConnection, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios, { AxiosResponse } from 'axios';
import WebServiceClient from './WebServiceClient';

class DirectNowClient {
    private static instance: DirectNowClient;
    private url: string = '';
    private ticket: string = '';
    private deviceGuid: string = '';
    private hubConnection: HubConnection | null = null;
    private webServiceClient: WebServiceClient = WebServiceClient.getInstance();

    constructor() {
    }

    static getInstance(): DirectNowClient {
        DirectNowClient.instance = DirectNowClient.instance || new DirectNowClient();
        return DirectNowClient.instance;
    }

    async initialize(deviceGuid: string, newInformationCallback: (...args: any[]) => void): Promise<boolean> {
        this.deviceGuid = deviceGuid;
        if (this.hubConnection) {
            await this.sendDirectNowCancel();
            this.hubConnection.off("NewInformation");
            await this.hubConnection.stop();
        }

        if (!this.url) {
            const storedUrl = await AsyncStorage.getItem('webServiceUrl');
            this.url = storedUrl + '/Realtime/DirectNow';
            const storedTicket = await AsyncStorage.getItem('connectionTicket');
            this.ticket = storedTicket ?? '';
        }
        await this.webServiceClient.createDirectNowConnection(this.deviceGuid);

        this.hubConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Information)
            .withUrl(this.url, { accessTokenFactory: () => this.ticket ?? '' })
            .withAutomaticReconnect()
            .withHubProtocol(new JsonHubProtocol())
            .build();

        this.hubConnection.on("NewInformation", (data)=> {
            if(data.deviceGuid == this.deviceGuid)
            {
                newInformationCallback(data);
            }
        });
        this.hubConnection.start()
            .catch(err => console.error('SignalR Connection Error: ', err));
        return true;
    }

    async sendDirectNowCommand (command: string) : Promise<any> {
        return this.webServiceClient.sendDirectNowCommand({
            Command: command,
            DeviceGuid: this.deviceGuid,
            DirectNowCommandType: 0
        });
    }

    async sendDirectNowCancel () : Promise<any> {
        return this.webServiceClient.sendDirectNowCommand({
            Command: '',
            DeviceGuid: this.deviceGuid,
            DirectNowCommandType: 5
        });
    }
}

export default DirectNowClient;
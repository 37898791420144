import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { useEffect, useState } from 'react';
import { AppRegistry, StyleSheet } from 'react-native';
import * as Notifications from 'expo-notifications';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import './components/extensions/dateExtensions'
import './components/extensions/stringExtensions'
import { MenuProvider } from 'react-native-popup-menu';

Notifications.setNotificationHandler({
  handleNotification: async ()=> ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true
  })
})

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  useEffect(()=> {registerForPushNotifications();})

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <ActionSheetProvider>
        <SafeAreaProvider>
          <MenuProvider>
            <Navigation colorScheme={colorScheme}/>
            <StatusBar />
          </MenuProvider>
        </SafeAreaProvider>
      </ActionSheetProvider>
    );
  }
}

const styles = StyleSheet.create({
  root:{
    flex: 1,
    backgroundColor: '#f9fbfc'
  }
})

async function registerForPushNotifications(){
  await Notifications.requestPermissionsAsync();
}

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });
import {RootTabParamList} from "../types";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import AlertsScreen from "../screens/AlertsScreen";
import {EventRegister} from "react-native-event-listeners";
import DevicesOverviewScreen from "../screens/DevicesOverviewScreen";
import * as React from "react";
import {AntDesign, FontAwesome} from "@expo/vector-icons";
import {createDrawerNavigator, DrawerItem} from '@react-navigation/drawer';
import {Text, View} from "react-native";

const Drawer = createDrawerNavigator<RootTabParamList>();

function DrawerNavigator() {
    const colorScheme = useColorScheme();

    return (
            <Drawer.Navigator
                initialRouteName="Alerts"
                useLegacyImplementation={true}
                screenOptions={{
                    drawerActiveTintColor: Colors[colorScheme].tint,
                    drawerType: "front",
                }}>
                <Drawer.Screen
                    name="Alerts"
                    component={AlertsScreen}
                    options={{
                        title: 'Alarme',
                        drawerIcon: ({color}) => <TabBarIcon name="bell" color={color}/>
                    }}
                    listeners={
                        {
                            drawerItemPress: (e) => {
                                EventRegister.emit('alertTabIconPressed')
                            }
                        }}/>
                <Drawer.Screen
                    name="Devices"
                    component={DevicesOverviewScreen}
                    options={{
                        title: 'Geräte',
                        drawerIcon: ({color}) => <TabBarIcon name="desktop" color={color}/>,
                    }}
                    listeners={
                        {
                            drawerItemPress: (e) => {
                                EventRegister.emit('deviceTabIconPressed')
                            }
                        }}
                />
            </Drawer.Navigator>
    );
}

export default DrawerNavigator

function TabBarIcon(props: {
    name: React.ComponentProps<typeof FontAwesome>['name'];
    color: string;
}) {
    return <FontAwesome size={25} style={{marginBottom: -3}} {...props} />;
}
import { View, Text, StyleSheet, Pressable, GestureResponderEvent, TouchableHighlight, Platform } from 'react-native'
import React, { useState } from 'react'
import useColorScheme from '../../hooks/useColorScheme';
import LottieView from 'lottie-react-native';
import * as Haptics from 'expo-haptics';

const CustomButtonWithCheckMark = ({ text, onPress, secondary }: { text: string, onPress: Function, secondary?: boolean }) => {
  const colorScheme = useColorScheme();
  const [buttonText, setButtonText] = useState(text);
  var animate: LottieView | null;
  return (
    <TouchableHighlight style={[colorScheme == 'dark' ? styles.containerDark : secondary ? styles.containerLightSecondary : styles.containerLight, styles.container]} underlayColor={colorScheme == 'dark' ? '#5a5a5a' : '#dadada'}
      onPress={() => {
        onPress();
        setButtonText('');
        if (Platform.OS != 'web') {
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
          animate?.play(0, 200);
        }
      }}>
      <View style={{ alignItems: 'center' }}>
        <Text style={[colorScheme == 'dark' ? styles.textDark : styles.textLight, styles.text]}>{buttonText}</Text>
        <LottieView style={{ width: 32, position: 'absolute', top: -3 }}

          ref={animation => {
            animate = animation;
          }}
          source={require('../../assets/lottie/58046-check-mark.json')}
          loop={false}
          onAnimationFinish={() => { animate?.reset(); setTimeout(() => setButtonText(text), 500) }}
        />
      </View>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 15,
    marginVertical: 5,
    alignItems: 'center',
    borderRadius: 10,
  },
  text: {
    fontWeight: 'bold',
  },
  containerLight: {
    backgroundColor: '#fff',
    borderColor: '#e8e8e8',
  },
  containerLightSecondary: {
    backgroundColor: '#eee',
    borderColor: '#e8e8e8',
  },
  containerDark: {
    backgroundColor: '#353535',
    borderColor: '#7a7a7a',
  },
  textDark: {
    color: '#fff'
  },
  textLight: {
    color: '#000'
  }
})

export default CustomButtonWithCheckMark
import {View, StyleSheet, TouchableHighlight, Platform, ViewStyle} from 'react-native'
import React from 'react'

const getStyles = (styleMobile: ViewStyle, styleWeb: ViewStyle) : ViewStyle[] => {
    if (Platform.OS == 'web')
        return  [styleWeb]
    return [styleMobile]
}

const CustomListItem = (props: any) => {
    return (
        <TouchableHighlight style={[styles.listItemContainer, (props.colorScheme == 'dark' ? styles.listItemContainerDark : styles.listItemContainerLight), getStyles(mobileStyles.listItemContainer, webStyles.listItemContainer)]}
            underlayColor={(props.colorScheme == 'dark' ? "#171917" : "#ddd")}
            onPress={props.onPress} onLongPress={props.onLongPress}>
            <View style={[styles.innerContainer, getStyles(mobileStyles.innerContainer, webStyles.innerContainer)]}>
                {props.children}
            </View>
        </TouchableHighlight>
    )
}
const webStyles = StyleSheet.create(
    {
        listItemContainer: {
            flex: 1,
            marginHorizontal: 5,
            paddingHorizontal: 5,
            marginVertical: '1%',
        },
        innerContainer: {
            flexWrap: "nowrap",
        }
    }
)


const mobileStyles = StyleSheet.create(
    {
        listItemContainer: {
            marginVertical: '2%'
        },
        innerContainer: {
            flexWrap: 'wrap'
        }
    }
)

const styles = StyleSheet.create(
    {
      innerContainer: {
          flexDirection: 'row',
          display: 'flex',
          flex: 1
      },
      listItemContainer: {
        width: '100%',
        borderRadius: 15,
        flexDirection: 'row',
        flexWrap: 'wrap',
        display: 'flex',
        paddingVertical: 5
    },
    listItemContainerLight: {
        color: '#3a3a3a',
        backgroundColor: '#fff',
    },
    listItemContainerDark: {
        color: '#f3f3f3',
        backgroundColor: '#212121'
    },
})

export default CustomListItem
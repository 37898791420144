import {KeyboardAvoidingView, Platform, StyleSheet, TouchableOpacity, View} from 'react-native'
import React, {useEffect, useState} from 'react'
import useColorScheme from '../hooks/useColorScheme';
import {RouteProp, StackActions, useNavigation, useRoute} from '@react-navigation/native';
import {Entypo, Feather, FontAwesome, FontAwesome5, MaterialCommunityIcons} from '@expo/vector-icons';
import CustomText from '../components/CustomText';
import WebServiceClient from '../components/util/WebServiceClient';
import {ICustomerSiteDeviceOverview, IDeviceTreeInformation} from '../interfaces/WebServiceInterfaces';
import {RootStackParamList} from '../types';
import * as Linking from 'expo-linking';
import * as Clipboard from 'expo-clipboard';
import ModalDragBar from '../components/ModalDragBar';
import DeviceRestartScreen from './DeviceRestartScreen';
import DeviceSerivceModeScreen from './DeviceServiceModeScreen';
import CustomActionButton from '../components/CustomActionButton';
import NotificationHandler from '../components/util/NotificationHandler';
import * as IntentLauncher from 'expo-intent-launcher';
import { Popable } from 'react-native-popable';

const DeviceDetailScreen = ({deviceInfo}: { deviceInfo?: ICustomerSiteDeviceOverview }) => {
    const route = useRoute<RouteProp<RootStackParamList, 'DeviceDetail'>>();
    let device = route.params ?? deviceInfo;
    const webServiceCLient = WebServiceClient.getInstance();
    const [detailedDevice, setDetailedDevice] = useState<IDeviceTreeInformation>({});
    const [showRestartModal, setShowRestartModal] = useState(false);
    const [showServiceModeModal, setShowServiceModeModal] = useState(false);
    const colorScheme = useColorScheme();
    const navigation = useNavigation();
    const notificationHandler = NotificationHandler.getInstance(navigation);
    const web = Platform.OS === "web";

    useEffect(() => {
        const fetchData = async () => {
            if (Number(route.params?.passedDeviceI3D) > 0) {
                await webServiceCLient.getDeviceOverviews({
                    DeviceI3Ds: [parseInt(route.params?.passedDeviceI3D)],
                }).then((deviceI3Ds: ICustomerSiteDeviceOverview[]) => {
                    device = deviceI3Ds[0];
                });
            }

            webServiceCLient.getDeviceTreeItemByFilter({
                I3Ds: [device.DeviceI3D]
            })
                .then((data) => {
                    setDetailedDevice(data[0]);
                })
            navigation.setParams({passedDeviceI3D: device.DeviceI3D});
        }
        fetchData().catch(console.error)
    }, [])

    const showDeviceRestart = () => {
        setShowRestartModal(true);
    }

    const showServiceMode = () => {
        setShowServiceModeModal(true);
    }

    const showAlerts = () => {
        navigation.dispatch(StackActions.push("DeviceAlerts", device));
    }

    const showAntivirusThreats = () => {
        navigation.dispatch(StackActions.push("DeviceAntivirusThreats", device));
    }

    const startSupRemoConnection = () => {
        if (!detailedDevice.SupRemoClientID) {
            return;
        }
        webServiceCLient.getSupRemoInformation({DeviceI3Ds: [device.DeviceI3D]})
            .then((data) => {
                if (Platform.OS === 'web') {
                    webServiceCLient.getSupRemoRiverSuiteProtocolLink(device.DeviceI3D)
                        .then((link) => {
                            Linking.openURL(link.toString());
                            return;
                        })
                } else {
                    Clipboard.setString(data[0].RemotePassword)
                }
            })
            .then(() => {
                if (Platform.OS === 'web')
                    return;

                if (Platform.OS === "ios") {
                    Linking.openURL("supremo-remote-desktop://supremoId=" + detailedDevice.SupRemoClientID);
                } else {
                    return IntentLauncher.startActivityAsync("android.intent.action.VIEW", {
                        packageName: "it.nanosystems.Supremo",
                        className: "com.embarcadero.firemonkey.FMXNativeActivity",
                        extra: {supremoId: detailedDevice.SupRemoClientID ?? ""},
                        type: "text/supremoID",
                        flags: 1,
                    });
                }
            })
            .then(() => notificationHandler.pushLocalNotification("SupRemo Passwort", "Passwort in die Zwischenablage kopiert!"))
            .catch((error) => console.log(error.toString()));
    }

    const getIcon = () => {
        return (<MaterialCommunityIcons name="remote-desktop" style={styles.actionButtonIcon}/>);
    }

    return (
        web ?
            <View style={{alignItems: 'center'}}>
                <View style={{flexDirection: 'row', alignSelf: 'flex-start'}}>
                    <FontAwesome size={30} resizeMode='contain'
                                 style={styles.deviceIcon}
                                 name={detailedDevice.DeviceClass == 'Server' ? 'server' : 'desktop'}
                                 color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}/>
                    <Entypo style={styles.deviceOnlineOverlay}
                            size={20}
                            name='dot-single'
                            color={detailedDevice.LastContact ? (((new Date().valueOf() - detailedDevice.LastContact.getDateFromMicrosoftJsonFormatted().valueOf()) / 1000 / 60) > 5 ? '#e74c3c' : '#2ecc71') : '#e74c3c'}/>
                    <CustomText style={styles.header} colorScheme={colorScheme}>{detailedDevice.ShortName}</CustomText>
                </View>

                <View style={{
                    flexDirection: 'row',
                    alignItems: 'stretch',
                    alignSelf: 'stretch',
                    alignContent: 'stretch'
                }}>
                    <View style={{flexDirection: 'column', alignItems: 'stretch', alignSelf: 'stretch', flex: '12'}}>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <FontAwesome5 size={20}
                                              color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                              style={styles.descriptionIcon}
                                              name="users"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{device.CustomerName}</CustomText>
                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <Entypo size={20}
                                        color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                        style={styles.descriptionIcon}
                                        name="location-pin"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.CustomerSiteName}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="power"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.DeviceUptime == null ? 'Letzte Startzeit unbekannt' : detailedDevice.DeviceUptime.getDateFromMicrosoftJsonFormatted().toLocaleString('de-DE')}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            {device.ScheduledRebootDateTime ?
                                <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                    <Feather size={20}
                                             color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                             style={styles.descriptionIcon}
                                             name="clock"/>
                                    <Feather size={10}
                                             color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                             style={[styles.descriptionIcon, {
                                                 position: 'absolute',
                                                 right: '20%',
                                                 bottom: -3,
                                                 alignSelf: 'flex-start'
                                             }]}
                                             name="power"/>
                                </View> : <></>}
                            {device.ScheduledRebootDateTime ?
                                <CustomText colorScheme={colorScheme}
                                            style={webStyles.description}>{device.ScheduledRebootDateTime.getDateFromMicrosoftJsonFormatted().toLocaleString('de-DE')}</CustomText>
                                : <></>}


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name={detailedDevice.LastContact ? (((new Date().valueOf() - detailedDevice.LastContact.getDateFromMicrosoftJsonFormatted().valueOf()) / 1000 / 60) > 5 ? 'wifi-off' : 'wifi') : 'alert-triangle'}/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.LastContact == null ? 'Kein RMM Agent installiert' : detailedDevice.LastContact.getDateFromMicrosoftJsonFormatted().toLocaleString('de-DE')}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <FontAwesome size={20}
                                             color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                             style={styles.descriptionIcon}
                                             name="user"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{device.LastUser ?? "Letzter Benutzer unbekannt"}</CustomText>


                        </View>
                        <View style={styles.spacing}/>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <FontAwesome size={20}
                                             color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                             style={styles.descriptionIcon}
                                             name="windows"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.OperatingSystem}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <MaterialCommunityIcons size={20}
                                                        color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                                        style={styles.descriptionIcon}
                                                        name="factory"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.Manufacturer}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="tag"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.Model}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="cpu"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.CPU}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <FontAwesome5 size={20}
                                              color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                              style={styles.descriptionIcon}
                                              name="network-wired"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.IPAddresses}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="monitor"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={webStyles.description}>{detailedDevice.GPU}</CustomText>


                        </View>

                        <View style={webStyles.container}>
                            {device.QuickNote ?
                                <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                    <FontAwesome size={20}
                                                 color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                                 style={styles.descriptionIcon}
                                                 name="sticky-note-o"/>
                                </View>
                                : <></>}

                            <View>
                                {device.QuickNote ?
                                    <CustomText colorScheme={colorScheme}
                                                style={webStyles.description}>{device.QuickNote}</CustomText>
                                    : <></>}

                            </View>


                            {detailedDevice.ServiceWorkStatus == 1 &&
                                <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                    <Feather size={20}
                                             color="#f1c40f"
                                             style={styles.descriptionIcon}
                                             name="tool"/>
                                </View>}
                            {detailedDevice.ServiceWorkStatus == 1 &&
                                <CustomText colorScheme={colorScheme} style={webStyles.description}>Gerät befindet sich
                                    in
                                    Wartung</CustomText>}


                        </View>

                        <View style={webStyles.container}>
                            {device.LastCheckResult != null && device.LastCheckResult != undefined ?
                                <View style={[webStyles.descriptionIconContainer, {marginHorizontal: 10}]}>
                                    <FontAwesome size={20}
                                                 style={styles.descriptionIcon}
                                                 name={device.LastCheckResult == 0 ? "check-circle" : device.LastCheckResult == 1 ? "times-circle" : "warning"}
                                                 color={device.LastCheckResult == 0 ? "#2ecc71" : device.LastCheckResult == 1 ? "#e74c3c" : "#f1c40f"}/>
                                </View>
                                : <></>}
                            {device.LastCheckResult != null && device.LastCheckResult != undefined ?
                                <CustomText colorScheme={colorScheme}
                                            style={webStyles.description}>{device.LastCheckResult == 0 ? 'Alles in Ordnung' : device.LastCheckResult == 1 ? 'Fehlerhafte Checks' : device.LastCheckResult == 2 ? 'Checks mit Warnung' : 'Kein Checkstatus bekannt'}</CustomText>
                                : <></>}


                        </View>


                    </View>


                    {detailedDevice.SupRemoClientID ?
                        (
                    <View style={{alignSelf: 'baseline', justifyContent: 'flex-start', flex: '1'}}>
                            <TouchableOpacity style={[{backgroundColor: '#3498db'}, webStyles.button]}
                                              onPress={() => startSupRemoConnection()}>
                                <Popable action="hover" content="SupRemo starten">
                                    <MaterialCommunityIcons name="remote-desktop" style={webStyles.actionButtonIcon}/>
                                </Popable>
                            </TouchableOpacity>
                    </View>
                    ) : ""}
                </View>
            </View>
            :
            <View>
                <KeyboardAvoidingView style={styles.root}>
                    <View style={styles.innerContainer}>
                        <ModalDragBar/>
                        <View style={styles.headerRow}>
                            <FontAwesome size={30} resizeMode='contain'
                                         style={styles.deviceIcon}
                                         name={detailedDevice.DeviceClass == 'Server' ? 'server' : 'desktop'}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}/>
                            <Entypo style={styles.deviceOnlineOverlay}
                                    size={20}
                                    name='dot-single'
                                    color={detailedDevice.LastContact ? (((new Date().valueOf() - detailedDevice.LastContact.getDateFromMicrosoftJsonFormatted().valueOf()) / 1000 / 60) > 5 ? '#e74c3c' : '#2ecc71') : '#e74c3c'}/>
                            <CustomText style={styles.header}
                                        colorScheme={colorScheme}>{detailedDevice.ShortName}</CustomText>
                        </View>

                        <View style={styles.deviceDetails}>
                            <View style={styles.descriptionIconContainer}>
                                <FontAwesome5 size={20}
                                              color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                              style={styles.descriptionIcon}
                                              name="users"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{device.CustomerName}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <Entypo size={20}
                                        color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                        style={styles.descriptionIcon}
                                        name="location-pin"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.CustomerSiteName}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="power"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.DeviceUptime == null ? 'Letzte Startzeit unbekannt' : detailedDevice.DeviceUptime.getDateFromMicrosoftJsonFormatted().toLocaleString('de-DE')}</CustomText>
                            {device.ScheduledRebootDateTime ? <View style={styles.descriptionIconContainer}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="clock"/>
                                <Feather size={10}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={[styles.descriptionIcon, {position: 'absolute', right: 0, bottom: -3}]}
                                         name="power"/>
                            </View> : <></>}
                            {device.ScheduledRebootDateTime ?
                                <CustomText colorScheme={colorScheme}
                                            style={styles.description}>{device.ScheduledRebootDateTime.getDateFromMicrosoftJsonFormatted().toLocaleString('de-DE')}</CustomText>
                                : <></>}
                            <View style={styles.descriptionIconContainer}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name={detailedDevice.LastContact ? (((new Date().valueOf() - detailedDevice.LastContact.getDateFromMicrosoftJsonFormatted().valueOf()) / 1000 / 60) > 5 ? 'wifi-off' : 'wifi') : 'alert-triangle'}/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.LastContact == null ? 'Kein RMM Agent installiert' : detailedDevice.LastContact.getDateFromMicrosoftJsonFormatted().toLocaleString('de-DE')}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <FontAwesome size={20}
                                             color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                             style={styles.descriptionIcon}
                                             name="user"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{device.LastUser ?? "Letzter Benutzer unbekannt"}</CustomText>
                            <View style={styles.spacing}/>
                            <View style={styles.descriptionIconContainer}>
                                <FontAwesome size={20}
                                             color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                             style={styles.descriptionIcon}
                                             name="windows"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.OperatingSystem}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <MaterialCommunityIcons size={20}
                                                        color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                                        style={styles.descriptionIcon}
                                                        name="factory"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.Manufacturer}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="tag"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.Model}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="cpu"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.CPU}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <FontAwesome5 size={20}
                                              color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                              style={styles.descriptionIcon}
                                              name="network-wired"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.IPAddresses}</CustomText>
                            <View style={styles.descriptionIconContainer}>
                                <Feather size={20}
                                         color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                         style={styles.descriptionIcon}
                                         name="monitor"/>
                            </View>
                            <CustomText colorScheme={colorScheme}
                                        style={styles.description}>{detailedDevice.GPU}</CustomText>
                            {device.QuickNote ? <View style={styles.descriptionIconContainer}>
                                    <FontAwesome size={20}
                                                 color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                                 style={styles.descriptionIcon}
                                                 name="sticky-note-o"/>
                                </View>
                                : <></>}
                            {device.QuickNote ?
                                <CustomText colorScheme={colorScheme}
                                            style={styles.description}>{device.QuickNote}</CustomText>
                                : <></>}
                            {detailedDevice.ServiceWorkStatus == 1 &&
                                <View style={styles.descriptionIconContainer}>
                                    <Feather size={20}
                                             color="#f1c40f"
                                             style={styles.descriptionIcon}
                                             name="tool"/>
                                </View>}
                            {detailedDevice.ServiceWorkStatus == 1 &&
                                <CustomText colorScheme={colorScheme} style={styles.description}>Gerät befindet sich in
                                    Wartung</CustomText>}
                            {device.LastCheckResult != null && device.LastCheckResult != undefined ?
                                <View style={styles.descriptionIconContainer}>
                                    <FontAwesome size={20}
                                                 style={styles.descriptionIcon}
                                                 name={device.LastCheckResult == 0 ? "check-circle" : device.LastCheckResult == 1 ? "times-circle" : "warning"}
                                                 color={device.LastCheckResult == 0 ? "#2ecc71" : device.LastCheckResult == 1 ? "#e74c3c" : "#f1c40f"}/>
                                </View>
                                : <></>}
                            {device.LastCheckResult != null && device.LastCheckResult != undefined ?
                                <CustomText colorScheme={colorScheme}
                                            style={styles.description}>{device.LastCheckResult == 0 ? 'Alles in Ordnung' : device.LastCheckResult == 1 ? 'Fehlerhafte Checks' : device.LastCheckResult == 2 ? 'Checks mit Warnung' : 'Kein Checkstatus bekannt'}</CustomText>
                                : <></>}

                        </View>


                        <View style={styles.actions}>
                        </View>
                    </View>
                </KeyboardAvoidingView>
                <CustomActionButton colorScheme={colorScheme}
                                    actionButtonItems={[
                                        {
                                            color: '#db3434',
                                            icon: (<Feather name="power" style={styles.actionButtonIcon}/>),
                                            onPress: showDeviceRestart,
                                            title: 'Gerät neustarten'
                                        },
                                        {
                                            color: '#9b59b6',
                                            icon: (<Feather name="tool" style={styles.actionButtonIcon}/>),
                                            onPress: showServiceMode,
                                            title: 'Wartungsmodus einrichten'
                                        },
                                        {
                                            color: '#1abc9c',
                                            icon: (<MaterialCommunityIcons name="alert-circle-outline"
                                                                           style={styles.actionButtonIcon}/>),
                                            onPress: showAlerts,
                                            title: 'Checks anzeigen'
                                        },
                                        device.GDataVersion && {
                                            color: 'orange',
                                            icon: (
                                                <MaterialCommunityIcons name="virus" style={styles.actionButtonIcon}/>),
                                            onPress: showAntivirusThreats,
                                            title: 'Virenfunde anzeigen'
                                        },
                                        device.SupRemoInstalled && {
                                            color: '#3498db',
                                            icon: getIcon(),
                                            onPress: startSupRemoConnection,
                                            title: 'Supremo Verbindung starten'
                                        }]}/>
                <DeviceRestartScreen show={showRestartModal} device={device}
                                     pressCallback={() => setShowRestartModal(false)}/>
                <DeviceSerivceModeScreen show={showServiceModeModal} device={device}
                                         pressCallback={() => setShowServiceModeModal(false)}/>
            </View>
    )
}

const webStyles = StyleSheet.create(
    {
        descriptionIconContainer: {
            width: '5%',
            alignSelf: 'flex-start',
            marginVertical: 2,
        },
        container: {
            flexDirection: 'row',
            marginHorizontal: 10
        },
        description: {
            fontSize: 16,
            alignSelf: 'center',
        },
        actionButtonIcon: {
            fontSize: 20,
            height: 22,
            color: 'white',
            alignSelf: 'center'
        },
        button: {
            marginHorizontal: 3,
            marginVertical: 3,
            borderRadius: 22.5,
            width: 45,
            height: 45,
            justifyContent: 'center'
        },
    }
)

const styles = StyleSheet.create(
    {
        root: {
            height: '100%',
            paddingHorizontal: 25,
            width: '100%',
        },
        innerContainer: {
            height: '100%',
            width: '100%',
        },
        headerRow: {
            flexDirection: 'row',
            width: '100%',
            marginVertical: 15
        },
        description: {
            fontSize: 16,
            width: '90%',
            alignSelf: 'center',
            justifyContent: 'space-evenly'
        },
        descriptionIcon: {
            alignSelf: 'center',
            justifyContent: 'space-evenly'
        },
        descriptionIconContainer: {
            width: '10%',
            alignSelf: 'flex-start',
            marginVertical: 2,
        },
        actions: {
            flex: 0,
            width: '100%',
            position: 'absolute',
            bottom: '7%',
        },
        header: {
            fontSize: 30
        },
        deviceIcon: {
            maxWidth: 100,
            maxHeight: 100,
            alignSelf: 'center',
            marginHorizontal: 15
        },
        deviceOnlineOverlay: {
            position: 'absolute',
            bottom: -5,
            left: 40
        },
        deviceDetails: {
            width: '100%',
            flexDirection: 'row',
            height: '100%',
            flexWrap: 'wrap',
            marginVertical: 5,
        },
        spacing: {
            height: 20,
            width: '100%'
        },
        actionButtonIcon: {
            fontSize: 20,
            height: 22,
            color: 'white',
        },
    }
)

export default DeviceDetailScreen
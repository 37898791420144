import {View, Text, FlatList, TouchableHighlight, StyleSheet, Platform, ViewStyle} from 'react-native'
import React, { useEffect, useState } from 'react'
import WebServiceClient from '../components/util/WebServiceClient';
import { FontAwesome } from '@expo/vector-icons';
import useColorScheme from '../hooks/useColorScheme';
import CustomText from '../components/CustomText';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useActionSheet } from '@expo/react-native-action-sheet';
import * as Haptics from 'expo-haptics';
import ModalDragBar from '../components/ModalDragBar';
import CustomListItem from '../components/CustomListItem';
import WebServiceEnumConverter from '../components/util/WebServiceEnumConverter';

const DeviceAlertsScreen = (props: any) => {
    const device = props.route.params;
    const [checksWithResults, setChecksWithResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const webserviceClient = WebServiceClient.getInstance();
    const colorScheme = useColorScheme();
    const navigation = useNavigation();
    const { showActionSheetWithOptions } = useActionSheet();

    const refresh = async () => {
        setLoading(true);
        webserviceClient.getMonChecksByFilter({
            DeviceI3Ds: [device.DeviceI3D],            
        }).then(async (data) => {
            return {checks: data, 
                results: await webserviceClient.getMonCheckDeviceResultsByFilter({
                    DeviceI3Ds: [device.DeviceI3D],        
                    CheckI3Ds: data.map((x: any) => x.I3D),  
                    OnlyLatest: true      
                })};
        }).then((data)=> setChecksWithResults(data.checks.map((check: any) => {return {
            check: check,
            latestResult: data.results.find((result: any) => result.CheckI3D == check.I3D)
        }})))
        .finally(() => setLoading(false));
    }
    const alertClicked = (alert: any) => {
        navigation.dispatch(StackActions.push("AlertDetail", {
            CheckI3D: alert.check.I3D,
            CheckName: alert.check.Name,
            CheckOutput: alert.latestResult?.CheckOutput,
            DeviceI3D: device.DeviceI3D,
            DeviceName: device.DeviceName,
            ExecutedAt: alert.latestResult?.ExecutedAt,
            DeviceClass: device.DeviceClass,
            CheckType: alert.check.CheckType,
            CheckResult: alert.latestResult?.CheckResult,
            CustomerName: device.CustomerName
        }));
    }

    const alertLongClicked = (item: any) => {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
        showActionSheetWithOptions({
            options: ["Cancel", "Alle Checks sofort ausführen", "Check auf OK setzen", "Checkergebnisse löschen"],
            destructiveButtonIndex: 3,
            cancelButtonIndex: 0,
            userInterfaceStyle: 'dark'
        },
            buttonIndex => {
                if (buttonIndex === 0) {
                    return;
                } else if (buttonIndex === 1) {
                    executeAllChecks(item);
                } else if (buttonIndex === 2) {
                    setOk(item);
                } else if (buttonIndex === 3) {
                    deleteCheckResults(item);
                }
            })
    }

    const getStyles = (styleMobile: ViewStyle, styleWeb: ViewStyle) : ViewStyle[] => {
        if (Platform.OS == 'web')
            return  [styleMobile, styleWeb]
        return [styleMobile]
    }

    const setOk = async (alert: any) => {
        webserviceClient.saveMonCheckDeviceResult([{
                CheckI3D: alert.check.I3D,
                DeviceI3D: device.DeviceI3D,
                ExecutedAt: '/Date(' + new Date().valueOf() + '+0200)/',
                CheckOutput: '{"Message":"Zurückgesetzt über die RiverSuite Mobile App"}',
                BundleI3D: alert.latestResult?.BundleI3D,
                CheckResult: 0
            }]).then(()=> refresh());

    }

    const executeAllChecks = async (alert: any) => {
        webserviceClient.setDeviceForImmediateExecution([alert.DeviceI3D]);
    }

    const deleteCheckResults = async (alert: any) => {
        webserviceClient.deleteMonCheckDeviceResults({
            DeviceI3Ds: [device.DeviceI3D],
            CheckI3Ds: [alert.check.I3D]
        }).then(() => refresh());
    }
    useEffect(() => {
        refresh();
    }, [])
    
  return (
    <View style={styles.root}>
        <ModalDragBar/>
        <FlatList style={getStyles(styles.list, webStyles.list)}
            keyExtractor={(f: any) => f.check.I3D}
            numColumns={Platform.OS == "web" ? 3 : 0}
            data={checksWithResults}
            renderItem={({item}) => (
                <CustomListItem onPress={() => alertClicked(item)} onLongPress={() => { alertLongClicked(item) }}  colorScheme={colorScheme}>
                    <View style={styles.alertContainerInner}>
                        <FontAwesome resizeMode='contain'
                            style={styles.resultIcon}
                            size={30}
                            name={item.latestResult?.CheckResult == 0 ? "check-circle" : item.latestResult?.CheckResult == 1 ? "times-circle" : "warning"}
                            color={item.latestResult?.CheckResult == 0 ? "#2ecc71" : item.latestResult?.CheckResult == 1 ? "#e74c3c" : "#f1c40f"}
                        />
                        <View style={{maxWidth: '80%'}}>
                            <CustomText colorScheme={colorScheme} style={{ opacity: 0.8, fontSize: 12 }}>{WebServiceEnumConverter.getCheckTypeString(item.check.CheckType)}</CustomText>
                            <CustomText colorScheme={colorScheme}>{item.check.Name}</CustomText>
                            <CustomText colorScheme={colorScheme}>Intervall: {WebServiceEnumConverter.getIntervalString(item.check.Interval)}</CustomText>
                            <CustomText colorScheme={colorScheme}>Letzte Ausführung: {item.latestResult && item.latestResult.ExecutedAt.getDateFromMicrosoftJsonFormatted().toLocaleString()}</CustomText>
                        </View>
                    </View>
                </CustomListItem>
            )} />
    </View>
  )
}

const webStyles = StyleSheet.create({
    alertContainer: {
        flex: 1,
        marginHorizontal: 5,
    },
    list: {
        flex: 1,
        flexDirection: "column",
        flexWrap: "wrap",
        alignSelf: "stretch"
    },
});


const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
        paddingHorizontal: '2%',
        paddingBottom: '15%',
    },
    alertContainer: {
        width: '100%',
        borderRadius: 15,
        flexDirection: 'row',
        flexWrap: 'wrap',
        display: 'flex',
        marginVertical: '2%',
        paddingVertical: 5
    },
    alertContainerInner: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        display: 'flex',
    },
    alertContainerLight: {
        color: '#3a3a3a',
        backgroundColor: '#fff',
    },
    alertContainerDark: {
        color: '#f3f3f3',
        backgroundColor: '#272927'
    },
    resultIcon: {
        height: '60%',
        maxWidth: 100,
        maxHeight: 100,
        alignSelf: 'center',
        marginHorizontal: 15
    },
    list: {
        width: '100%',
        height: '100%'
    },
})

export default DeviceAlertsScreen
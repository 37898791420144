import {
    Animated,
    Button,
    Easing,
    FlatList,
    KeyboardAvoidingView,
    Platform,
    ScrollView,
    StyleSheet,
    Switch,
    Text,
    TouchableOpacity,
    View,
    ViewStyle,
} from 'react-native'
import { Popable } from 'react-native-popable';
import React, {useEffect, useRef, useState} from 'react'
import {StackActions, useNavigation} from '@react-navigation/native';
import useColorScheme from '../hooks/useColorScheme';
import CustomCircularButton from '../components/CustomCircularButton/CustomCircularButton';
import CustomInput from '../components/CustomInput';
import {AntDesign, FontAwesome, FontAwesome5} from '@expo/vector-icons';
import {useActionSheet} from '@expo/react-native-action-sheet';
import * as Haptics from 'expo-haptics';
import WebServiceClient from '../components/util/WebServiceClient';
import {EventRegister} from 'react-native-event-listeners';
import Modal from "react-native-modal";
import CustomText from '../components/CustomText';
import {IAlertDetail, IDeviceResultOverview} from '../interfaces/WebServiceInterfaces';
import CustomButton from '../components/CustomButton';
import CustomComboBox from '../components/CustomComboBox';
import SetCheckResultOkScreen from './SetCheckResultOkScreen';
import CustomListItem from '../components/CustomListItem';
import WebServiceEnumConverter from '../components/util/WebServiceEnumConverter';
import {DataTable} from 'react-native-paper';
import {DrawerContentScrollView} from "@react-navigation/drawer";

const AlertsScreen = (props: any) => {
    const [alerts, setAlerts] = useState<IDeviceResultOverview[]>([]);
    const [filteredAlerts, setFilteredAlerts] = useState<IDeviceResultOverview[]>([]);
    const [lastSelectedAlert, setLastSelectedAlert] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showSetCheckOk, setShowSetCheckOk] = useState(false);
    const { showActionSheetWithOptions } = useActionSheet();
    const [filterSuccess, setFilterSuccess] = useState(false);
    const [filterWarning, setFilterWarning] = useState(true);
    const [filterError, setFilterError] = useState(true);
    const [search, setSearch] = useState('');
    const [filterCustomers, setFilterCustomers] = useState([])
    const [selectedFilterCustomers, setSelectedFilterCustomers] = useState([])
    const [selectedAlert, setSelectedAlert] = useState<IDeviceResultOverview | undefined>(undefined);
    const [sortIndex, setSortIndex] = useState(0);
    const [sortDesc, setSortDesc] = useState(false);
    const [sortedAlerts, setSortedAlerts] = useState<IDeviceResultOverview[]>([]);

    const webserviceClient = WebServiceClient.getInstance();
    const navigation = useNavigation();
    const colorScheme = useColorScheme();
    const web = Platform.OS === "web";


    useEffect(() => {
        filter();
    }, [search]);
    useEffect(() => {
        filter();
    }, [alerts]);
    useEffect(() => {
        expandedValue.setValue(0);
        if(!selectedAlert){
            return;
        }
        Animated.timing(expandedValue, {
            toValue: 1,
            duration: 300,
            easing: Easing.ease,
            useNativeDriver: true
        }).start();
    }, [selectedAlert]);
    
    const expandedValue = useRef(new Animated.Value(0)).current;

    const refresh = async () => {
        setLoading(true);
        webserviceClient.getCustomers()
            .then((data) => { 
                if(filterCustomers.length > 0){
                    return filterCustomers;
                }
                setFilterCustomers(data); 
                return data; 
            })
            .then((data) => webserviceClient.getDeviceResultOverviews({
                ResultsStatus: [filterSuccess ? 0 : null, filterError ? 1 : null, filterWarning ? 2 : null],
                DeviceClasses: ["Server", "Workstation"],
                CustomerI3Ds: selectedFilterCustomers.length > 0 ? selectedFilterCustomers.map((f: any) => f.I3D) : data.map((f: { [x: string]: any; }) => f["I3D"])
            })).then(async (data) => {
                setAlerts(data);
                setLoading(false)
            });
    }
    useEffect(() => {
        refresh();
    }, [props])
    const alertClicked = (alert: IAlertDetail) => {
        navigation.navigate("AlertDetail", alert);
    }
    const filter = () => {
        setFilteredAlerts(alerts.filter((item: any) => item.CustomerName.toLowerCase().includes(search.toLowerCase())
            || item.DeviceName.toLowerCase().includes(search.toLowerCase())
            || item.CheckName.toLowerCase().includes(search.toLowerCase())
            || WebServiceEnumConverter.getCheckTypeString(item.CheckType).toLowerCase().includes(search.toLowerCase())));
    }

    const alertLongClicked = (item: any) => {
        setLastSelectedAlert(item);
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
        showActionSheetWithOptions({
            options: ["Cancel", "Gerät öffnen", "Alle Checks sofort ausführen", "Check auf OK setzen", "Checkergebnisse löschen"],
            destructiveButtonIndex: 4,
            cancelButtonIndex: 0,
            userInterfaceStyle: colorScheme
        },
            buttonIndex => {
                if (buttonIndex === 0) {
                    return;
                } else if (buttonIndex === 1) {
                    openDevice(item);
                }
                else if (buttonIndex === 2) {
                    executeAllChecks(item);
                } else if (buttonIndex === 3) {
                    setShowSetCheckOk(true);
                } else if (buttonIndex === 4) {
                    deleteCheckResults(item);
                }
            })
    }

    const openDevice = async (alert: any) => {
        navigation.navigate("DeviceDetail", alert);
    }

    const executeAllChecks = async (alert: any) => {
        webserviceClient.setDeviceForImmediateExecution([alert.DeviceI3D]);
    }

    const deleteCheckResults = async (alert: any) => {
        webserviceClient.deleteMonCheckDeviceResults({
            DeviceI3Ds: [alert.DeviceI3D],
            CheckI3Ds: [alert.CheckI3D]
        }).then(() => refresh());
    }

    const getStyles = (styleMobile: ViewStyle, styleWeb: ViewStyle) : ViewStyle[] => {
        if (Platform.OS == 'web')
            return  [styleMobile, styleWeb]
        return [styleMobile]
    }
    const openDeviceDetail = async (alert: any) => {
        navigation.dispatch(StackActions.push("DeviceDetail", alert));
    }
    const sort = () => {
        switch (sortIndex){
            case 0:
                return filteredAlerts.sort(function (a, b): number {
                    let nameA = a.CustomerName.toLowerCase(), nameB = b.CustomerName.toLowerCase();
                    let indexA = a.DeviceI3D.toString() + "check" + a.CheckI3D.toString(), indexB =  b.DeviceI3D.toString() + "check" + b.CheckI3D.toString()
                    if (nameA < nameB)
                        return sortDesc ? 1 : -1
                    if (nameA == nameB){
                        if(indexA < indexB)
                            return sortDesc ? 1 : -1
                        return sortDesc ? -1 : 1;
                    }
                    return sortDesc ? -1 : 1
                })
            case 1:
                return filteredAlerts.sort(function (a, b): number {
                    let nameA = a.DeviceName.toLowerCase(), nameB = b.DeviceName.toLowerCase();
                    let indexA = a.DeviceI3D.toString() + "check" + a.CheckI3D.toString(), indexB =  b.DeviceI3D.toString() + "check" + b.CheckI3D.toString()
                    if (nameA < nameB)
                        return sortDesc ? 1 : -1
                    if (nameA == nameB){
                        if(indexA < indexB)
                            return sortDesc ? 1 : -1
                        return sortDesc ? -1 : 1;
                    }
                    return sortDesc ? -1 : 1
                })
            case 2:
                return filteredAlerts.sort(function (a, b): number {
                    let nameA = a.CheckName.toLowerCase(), nameB = b.CheckName.toLowerCase();
                    let indexA = a.DeviceI3D.toString() + "check" + a.CheckI3D.toString(), indexB =  b.DeviceI3D.toString() + "check" + b.CheckI3D.toString()
                    if (nameA < nameB)
                        return sortDesc ? 1 : -1
                    if (nameA == nameB){
                        if(indexA < indexB)
                            return sortDesc ? 1 : -1
                        return sortDesc ? -1 : 1;
                    }
                    return sortDesc ? -1 : 1
                })
            case 3:
                return filteredAlerts.sort(function (a, b): number {
                    let nameA = WebServiceEnumConverter.getCheckTypeString(a.CheckType).toLowerCase(), nameB = WebServiceEnumConverter.getCheckTypeString(b.CheckType).toLowerCase();
                    let indexA = a.DeviceI3D.toString() + "check" + a.CheckI3D.toString(), indexB =  b.DeviceI3D.toString() + "check" + b.CheckI3D.toString()
                    if (nameA < nameB)
                        return sortDesc ? 1 : -1
                    if (nameA == nameB){
                        if(indexA < indexB)
                            return sortDesc ? 1 : -1
                        return sortDesc ? -1 : 1;
                    }
                    return sortDesc ? -1 : 1
                })
            case 4:
                return filteredAlerts.sort(function (a, b): number {
                    let nameA = a.CheckResult == 1 ? 100 : a.CheckResult, nameB = b.CheckResult == 1 ? 100 : b.CheckResult;
                    let indexA = a.DeviceI3D.toString() + "check" + a.CheckI3D.toString(), indexB =  b.DeviceI3D.toString() + "check" + b.CheckI3D.toString()
                    if (nameA < nameB)
                        return sortDesc ? 1 : -1
                    if (nameA == nameB){
                        if(indexA < indexB)
                            return sortDesc ? 1 : -1
                        return sortDesc ? -1 : 1;
                    }
                    return sortDesc ? -1 : 1
                })
            case 5:
                return filteredAlerts.sort(function (a, b): number {
                    let nameA = a.ExecutedAt.toLowerCase(), nameB = b.ExecutedAt.toLowerCase();
                    let indexA = a.DeviceI3D.toString() + "check" + a.CheckI3D.toString(), indexB =  b.DeviceI3D.toString() + "check" + b.CheckI3D.toString()
                    if (nameA < nameB)
                        return sortDesc ? 1 : -1
                    if (nameA == nameB){
                        if(indexA < indexB)
                            return sortDesc ? 1 : -1
                        return sortDesc ? -1 : 1;
                    }
                    return sortDesc ? -1 : 1
                })
            default:
                return filteredAlerts;
        }
    }
    const updateSorting = (index: number) => {
        if(sortIndex == index) 
        {
            setSortDesc(!sortDesc)
        } else 
        {
            setSortDesc(false)
        }
        setSortIndex(index); 
    }

    return (
        web ?
            <DrawerContentScrollView ref={data => {
                EventRegister.removeEventListener('alertTabIconPressed');
                EventRegister.addEventListener('alertTabIconPressed', () => {
                    data?.scrollTo(0, 0, true);
                });
            }}
                                     stickyHeaderIndices={[0,1]}>
                <View style={[styles.root, webStyles.horizontal, webStyles.root]}>
                    {/*SearchBar*/}
                    <CustomInput placeholder='Suche' value={search} setValue={setSearch} clearButtonMode='always'
                                 style={webStyles.customerSearchBar}/>
                    {/*FilterBar*/}
                    <View style={[webStyles.horizontal, webStyles.root, styles.root, webStyles.filterBar]}>
                        <View style={styles.filterSettingsContainer}>
                            <FontAwesome style={styles.filterIcon} name='times-circle' size={24}
                                         color="#e74c3c"/>
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Fehler</CustomText>
                            <Switch style={styles.filterSwitch} value={filterError}
                                    onValueChange={setFilterError}/>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <FontAwesome style={styles.filterIcon} name='warning' size={24}
                                         color="#f1c40f"/>
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Warnung</CustomText>
                            <Switch style={styles.filterSwitch} value={filterWarning}
                                    onValueChange={setFilterWarning}/>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <FontAwesome style={styles.filterIcon} name='check-circle' size={24}
                                         color="#2ecc71"/>
                            <CustomText style={styles.filterText}
                                        colorScheme={colorScheme}>Erfolgreich</CustomText>
                            <Switch style={styles.filterSwitch} value={filterSuccess}
                                    onValueChange={setFilterSuccess}/>
                        </View>
                    </View>
                    {/*CustomerSelection*/}
                    <View style={webStyles.customerSelection}>
                        <CustomComboBox closedText='Kunden auswählen' uniqueKey="I3D" displayMember='Name'
                                        colorScheme={colorScheme} items={filterCustomers}
                                        selectedItems={selectedFilterCustomers}
                                        setSelectedItems={setSelectedFilterCustomers}
                                        style={{position: 'absolute'}}/>
                    </View>
                    <View style={webStyles.acceptButton}>
                        <CustomButton text='Anwenden' onPress={() => {
                            setShowFilter(false);
                            refresh();
                        }} secondary/>
                    </View>
                </View>
            <SetCheckResultOkScreen show={showSetCheckOk} pressCallback={() => setShowSetCheckOk(false)} alert={selectedAlert} />


                <View style={[styles.root, webStyles.root]}>
                    <DataTable>
                        <DataTable.Header style={webStyles.headerBar} >
                            <DataTable.Title onPress={()=> { updateSorting(0); }}>Kunde {sortIndex == 0 && !sortDesc && '▲'}{sortIndex == 0 && sortDesc && '▼'}</DataTable.Title>
                            <DataTable.Title onPress={()=> { updateSorting(1); }}>Gerät {sortIndex == 1 && !sortDesc && '▲'}{sortIndex == 1 && sortDesc && '▼'}</DataTable.Title>
                            <DataTable.Title onPress={()=> { updateSorting(2); }}>Checkname {sortIndex == 2 && !sortDesc && '▲'}{sortIndex == 2 && sortDesc && '▼'}</DataTable.Title>
                            <DataTable.Title onPress={()=> { updateSorting(3); }}>Check Art {sortIndex == 3 && !sortDesc && '▲'}{sortIndex == 3 && sortDesc && '▼'}</DataTable.Title>
                            <DataTable.Title onPress={()=> { updateSorting(4); }}>Letztes Ergebnis {sortIndex == 4 && !sortDesc && '▲'}{sortIndex == 4 && sortDesc && '▼'}</DataTable.Title>
                            <DataTable.Title onPress={()=> { updateSorting(5); }}>Ausgeführt am {sortIndex == 5 && !sortDesc && '▲'}{sortIndex == 5 && sortDesc && '▼'}</DataTable.Title>
                        </DataTable.Header>
                        { sort().map(item => {
                            return (
                                <View key={item.CheckI3D.toString() + "device" + item.DeviceI3D.toString()}>
                                    <DataTable.Row style={[item == selectedAlert && {backgroundColor: '#c7ecee'},selectedAlert != item && sort().indexOf(item) % 2 == 1 && {backgroundColor: '#efefef'}]}
                                                   key={item.CheckI3D + "device" + item.DeviceI3D}
                                                   onPress={() => {
                                                       selectedAlert == item ? setSelectedAlert(undefined) : setSelectedAlert(item)
                                                   }}>
                                        <DataTable.Cell>{item.CustomerName}</DataTable.Cell>
                                        <DataTable.Cell>{item.DeviceName}</DataTable.Cell>
                                        <DataTable.Cell>{item.CheckName}</DataTable.Cell>
                                        <DataTable.Cell>{WebServiceEnumConverter.getCheckTypeString(item.CheckType)}</DataTable.Cell>
                                        <DataTable.Cell>
                                            <FontAwesome resizeMode='contain'
                                                         style={styles.resultIcon}
                                                         size={30}
                                                         name={item.CheckResult == 0 ? "check-circle" : item.CheckResult == 1 ? "times-circle" : "warning"}
                                                         color={item.CheckResult == 0 ? "#2ecc71" : item.CheckResult == 1 ? "#e74c3c" : "#f1c40f"}/>
                                        </DataTable.Cell>
                                        <DataTable.Cell>{item.ExecutedAt.getDateFromMicrosoftJsonFormatted().toLocaleString()}</DataTable.Cell>

                                    </DataTable.Row>
                                    {item == selectedAlert && (
                                        <View style={[webStyles.alertDetailContainer, {
                                            flexDirection: 'column',
                                            alignItems: 'stretch'}]}>


                                            <View style={{
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignItems: 'stretch',
                                                justifyContent: 'space-between'
                                            }}>
                                                {/*Check info*/}
                                                <View style={{flexDirection: 'column'}}>
                                                    {/*DeviceName*/}
                                                    <View style={[webStyles.horizontal, webStyles.verticalSpacing]}>
                                                        <View>
                                                            <FontAwesome
                                                                name={item.DeviceClass == 'Server' ? 'server' : 'desktop'}
                                                                size={20}
                                                                color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}/>
                                                        </View>
                                                        <CustomText style={webStyles.horizontalSpacing}
                                                                    colorScheme={colorScheme}>{item.DeviceName}</CustomText>
                                                    </View>

                                                    {/*CustomerName*/}
                                                    <View style={[webStyles.horizontal, webStyles.verticalSpacing]}>
                                                        <View>
                                                            <FontAwesome5 size={20}
                                                                          color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}
                                                                          name="users"/>
                                                        </View>
                                                        <CustomText style={webStyles.horizontalSpacing}
                                                                    colorScheme={colorScheme}>{item.CustomerName}</CustomText>
                                                    </View>
                                                </View>

                                                {/*Buttons*/}
                                                <View style={{
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignSelf: 'flex-start',
                                                    alignItems: 'flex-end'
                                                }}>
                                                    <Popable action="hover" content="Gerät öffnene">
                                                        <TouchableOpacity
                                                            style={[{backgroundColor: '#3498db'}, webStyles.button]}
                                                            onPress={() => openDeviceDetail(item)}>
                                                            <FontAwesome
                                                                name={item.DeviceClass == 'Server' ? 'server' : 'desktop'}
                                                                style={webStyles.actionButtonIcon}/>
                                                        </TouchableOpacity>
                                                    </Popable>
                                                    <Popable action="hover" content="Auf Ok setzen">
                                                        <TouchableOpacity
                                                            style={[{backgroundColor: '#2ecc71'}, webStyles.button]}
                                                            onPress={() => setShowSetCheckOk(true)}>
                                                            <AntDesign name="check" style={webStyles.actionButtonIcon}/>
                                                        </TouchableOpacity>
                                                    </Popable>
                                                    <Popable action="hover" content="Alle Checks ausführen">
                                                        <TouchableOpacity
                                                            style={[{backgroundColor: '#9b59b6'}, webStyles.button]}
                                                            onPress={() => executeAllChecks(item)}>
                                                            <AntDesign name="doubleright"
                                                                       style={webStyles.actionButtonIcon}/>
                                                        </TouchableOpacity>
                                                    </Popable>
                                                    <Popable action="hover" content="Ergebnisse löschen">
                                                        <TouchableOpacity
                                                            style={[{backgroundColor: '#db3434'}, webStyles.button]}
                                                            onPress={() => deleteCheckResults(item)}>
                                                            <AntDesign name="delete"
                                                                       style={webStyles.actionButtonIcon}/>
                                                        </TouchableOpacity>
                                                    </Popable>
                                                </View>
                                            </View>


                                            {/*Additional Info */}
                                            <View style={[{width: '100%'}]}>
                                                <View style={[webStyles.horizontal, webStyles.verticalSpacing]}>
                                                <View>
                                                    <AntDesign name='clockcircleo'
                                                               size={20}
                                                               color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'}/>
                                                </View>
                                                <CustomText style={webStyles.horizontalSpacing}
                                                    colorScheme={colorScheme}>{item.ExecutedAt ? item.ExecutedAt.getDateFromMicrosoftJsonFormatted().toLocaleString() : "Noch nicht ausgeführt"}
                                                </CustomText>
                                                </View>

                                                <View style={{
                                                    borderRadius: 10,
                                                    shadowColor: colorScheme === 'dark' ? '#333' : '#ccc',
                                                    shadowOpacity: 0.3,
                                                    shadowRadius: 10,
                                                    backgroundColor: colorScheme === 'dark' ? '#1a1a1a' : '#fff',
                                                    width: '100%',
                                                    padding: 10,
                                                    marginVertical: 15,
                                                    flex: 1,
                                                    maxHeight: '80%'
                                                }}>
                                                    <ScrollView nestedScrollEnabled>
                                                        <CustomText colorScheme={colorScheme}
                                                                    selectable>{JSON.parse(item.CheckOutput ?? '{}').Message?.trim() ?? "Kein Ergebnis"}</CustomText>
                                                    </ScrollView>
                                                </View>
                                            </View>


                                        </View>
                                    )}
                                </View>)
                        })}
                    </DataTable>
                </View>
            </DrawerContentScrollView> :
        <View style={styles.root}>
            <CustomInput placeholder='Suche' value={search} setValue={setSearch} clearButtonMode='always' />
            <FlatList style={getStyles(styles.list, webStyles.list)}
                numColumns={Platform.OS == "web" ? 3 : 0}
                keyExtractor={(f) => f.CheckI3D + "device" + f.DeviceI3D}
                data={filteredAlerts}
                ref={data => {
                    EventRegister.removeEventListener('alertTabIconPressed');
                    EventRegister.addEventListener('alertTabIconPressed', () => {
                        data?.scrollToOffset({ animated: true, offset: 0 });
                    });
                }}
                renderItem={({ item }) => (
                    <CustomListItem onPress={() => alertClicked(item)} onLongPress={() => { alertLongClicked(item) }} colorScheme={colorScheme}>
                        <FontAwesome resizeMode='contain'
                            style={styles.resultIcon}
                            size={30}
                            name={item.CheckResult == 0 ? "check-circle" : item.CheckResult == 1 ? "times-circle" : "warning"}
                            color={item.CheckResult == 0 ? "#2ecc71" : item.CheckResult == 1 ? "#e74c3c" : "#f1c40f"}
                        />
                        <View style={{ maxWidth: '80%' }}>
                            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <CustomText colorScheme={colorScheme} style={{ opacity: 0.8, fontSize: 12 }}>{WebServiceEnumConverter.getCheckTypeString(item.CheckType)}</CustomText>
                                <CustomText colorScheme={colorScheme} style={{ alignSelf: 'flex-end', opacity: 0.8, fontSize: 12 }}>{item.ExecutedAt.getDateFromMicrosoftJsonFormatted().toLocaleString()}</CustomText>
                            </View>
                            <CustomText colorScheme={colorScheme} style={styles.containerHeader}>{item.CheckName}</CustomText>
                            <CustomText colorScheme={colorScheme}>{item.DeviceName}</CustomText>
                            <CustomText colorScheme={colorScheme}>{item.CustomerName}</CustomText>
                        </View>
                    </CustomListItem>
                )}
                refreshing={loading}
                onRefresh={refresh} />
            <Modal style={styles.filterModal} isVisible={showFilter} onBackdropPress={() => setShowFilter(false)}>
                <KeyboardAvoidingView behavior='position'>
                    <View style={[styles.filterRoot, colorScheme == 'dark' ? styles.filterRootDark : styles.filterRootLight]}>
                        <Text style={[(colorScheme == 'dark' ? styles.textDark : styles.textLight), styles.filterHeader]}>Filteroptionen</Text>
                        <View style={styles.filterSettingsContainer}>
                            <Switch style={styles.filterSwitch} value={filterError} onValueChange={setFilterError} />
                            <FontAwesome style={styles.filterIcon} name='times-circle' size={24}
                                color="#e74c3c" />
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Fehler</CustomText>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <Switch style={styles.filterSwitch} value={filterWarning} onValueChange={setFilterWarning} />
                            <FontAwesome style={styles.filterIcon} name='warning' size={24}
                                color="#f1c40f" />
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Warnung</CustomText>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <Switch style={styles.filterSwitch} value={filterSuccess} onValueChange={setFilterSuccess} />
                            <FontAwesome style={styles.filterIcon} name='check-circle' size={24}
                                color="#2ecc71" />
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Erfolgreich</CustomText>
                        </View>
                        <CustomComboBox closedText='Kunden auswählen' uniqueKey="I3D" displayMember='Name' colorScheme={colorScheme} items={filterCustomers} selectedItems={selectedFilterCustomers} setSelectedItems={setSelectedFilterCustomers} />
                        <View style={styles.filterSettingsContainer}>
                            <CustomButton text='Anwenden' onPress={() => { setShowFilter(false); refresh(); }} secondary />
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </Modal>
            <View style={styles.filterButton}>
                <CustomCircularButton iconName={showFilter ? 'times' : 'filter'}
                    colorScheme={colorScheme}
                    onPress={() => {
                        setShowFilter(!showFilter);
                    }} />
            </View>
            <SetCheckResultOkScreen show={showSetCheckOk} pressCallback={() => setShowSetCheckOk(false)} alert={lastSelectedAlert} />


            <View style={styles.filterButton}>
                <CustomCircularButton iconName={showFilter ? 'times' : 'filter'}
                    colorScheme={colorScheme}
                    onPress={() => {
                        setShowFilter(!showFilter);
                    }} />
            </View>
        </View>
    )
}

const webStyles = StyleSheet.create({
    root: {
        backgroundColor: '#F5F5F5'
    },
    transparent:{
        backgroundColor: 'rgba(0, 0, 0, 0.0)'
    },
    headerBar: {
        backgroundColor: '#F5F5F5'
    },
    alertContainer: {
        flex: 1,
        marginHorizontal: 5,
    },
    list: {
        flex: 1,
        flexDirection: "column",
        flexWrap: "wrap",
        alignSelf: "stretch"
    },
    container: {
        paddingTop: 10,
        paddingHorizontal: 30,
    },
    horizontal:{
        flexDirection: "row",
        flexWrap: "wrap"
    },
    horizontalSpacing:{
        marginHorizontal: 3,
        alignSelf: 'center'
    },
    verticalSpacing:{
        marginVertical: 3,
    },
    justify:{
        justifyContent: 'space-evenly',
        alignContent: 'stretch',
        alignSelf: 'stretch'
    },
    stretch:{
        alignItems: "stretch"
    },
    wrapOverflow:{
        flexWrap: 'wrap',
        flexDirection: "column",
        flex: 1
    },
    filterVertical:{
        flexDirection: "column",
        marginHorizontal: 3
    },
    customerSearchBar:{
        alignSelf: 'center',
        flex: 5,
        marginHorizontal: 1
    },
    filterBar:{
        flex: 4,
        alignSelf: 'center',
        marginHorizontal: 1
    },
    customerSelection:{
        flex: 2,
        marginHorizontal: 1,
        alignSelf: 'center',
        height: 50
    },
    acceptButton:{
        flex: 1,
        marginHorizontal: 1,
        overflow: 'hidden'
    },
    customerWidth:{
        width: '60%',
    },
    alertDetailContainer:{
        marginVertical: 8,
        marginHorizontal: 20,
    },
    button: {
        marginHorizontal: 3,
        marginVertical: 3,
        borderRadius: 22.5,
        width: 45,
        height: 45,
        justifyContent: 'center'
    },
    actionButtonIcon: {
        fontSize: 20,
        height: 22,
        color: 'white',
        alignSelf: 'center'
    }
});


const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
        paddingHorizontal: '2%',
    },
    textDark: {
        color: '#f3f3f3',
    },
    textLight: {
        color: '#3a3a3a'
    },
    containerHeader: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    resultIcon: {
        height: '60%',
        width: 32,
        maxHeight: 100,
        alignSelf: 'center',
        marginHorizontal: 15
    },
    list: {
        width: '100%',
        height: '90%'
    },
    loadingIndicator: {
        alignSelf: 'center',
        zIndex: 100,
        display: 'flex'
    },
    filter: {
        width: '100%'
    },
    filterButton: {
        position: 'absolute',
        bottom: '5%',
        right: '10%',
        width: 60,
        height: 60,
    },
    filterRoot: {
        width: '100%',
        flexDirection: 'column',
        paddingHorizontal: 20,
        paddingBottom: 40,
    },
    filterRootDark: {
        backgroundColor: '#111'
    },
    filterRootLight: {
        backgroundColor: '#fff'
    },
    filterModal: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    filterHeader: {
        fontSize: 24,
        textAlign: 'center',
        marginVertical: 15
    },
    filterSettingsContainer: {
        flexDirection: 'row',
        marginVertical: 10
    },
    filterText: {
        fontSize: 18,
        alignSelf: 'center',
        marginHorizontal: 15
    },
    filterSwitch: {
        marginRight: 15
    },
    filterIcon: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    }
})
export default AlertsScreen
import { View, StyleSheet, KeyboardAvoidingView, Switch } from 'react-native'
import React, { useState } from 'react'
import DateTimePicker from '@react-native-community/datetimepicker';
import useColorScheme from '../hooks/useColorScheme';
import CustomText from '../components/CustomText';
import WebServiceClient from '../components/util/WebServiceClient';
import CustomButtonWithCheckMark from '../components/CustomButtonWithCheckMark/CustomButtonWithCheckMark';
import Modal from "react-native-modal";
import CustomInput from '../components/CustomInput';
import CustomDateTimePicker from '../components/CustomDateTimePicker';
import { ToggleButton } from 'react-native-paper';

const DeviceSerivceModeScreen = ({device, show, pressCallback}: {device: any, show: boolean, pressCallback: Function}) => {
    const [serviceModeStartDate, setServiceModeStartDate] = useState(new Date());
    const [serviceModeEndDate, setServiceModeEndDate] = useState(new Date());
    const [reason, setServiceModeReason] = useState('');
    const [preventCheckExecution, setPreventCheckExecution] = useState(false);
    const colorScheme = useColorScheme();
    const webServiceClient = WebServiceClient.getInstance();

    const setServiceMode = () => {
        webServiceClient.saveOrUpdateMonServiceModes([{
            DeviceI3D: device.DeviceI3D,
            CustomerI3D: device.CustomerI3D,
            CustomerSiteI3D: device.CustomerSiteI3D,
            DeviceClass: device.DeviceClass == "Server" ? 1 : 5,
            Name: reason,
            PreventCheckExecution: preventCheckExecution,
            TimingSetting: {
                Repeat: false,
                StartTime: serviceModeStartDate.getMicrosoftJsonFormatted(),
                EndTime: serviceModeEndDate.getMicrosoftJsonFormatted(),
            }
        }])
        .then(()=> setTimeout(()=> pressCallback(), 1000))
    }
    return (
        <Modal isVisible={show} animationIn='slideInUp' coverScreen={false} style={styles.modal} onBackdropPress={()=>pressCallback()} avoidKeyboard>
            <KeyboardAvoidingView behavior='position'>
                <View style={[colorScheme == 'dark' ? styles.rootDark : styles.rootLight ,styles.root]}>
                    <CustomText style={styles.header} colorScheme={colorScheme}>Wartungsmodus</CustomText>
                    <CustomText style={styles.subHeader} colorScheme={colorScheme}>{device.DeviceName}</CustomText>
                    <CustomInput clearButtonMode='always' setValue={setServiceModeReason} value={reason} placeholder='Grund'/>
                    <View style={styles.datePickerContainer}>
                        <CustomText style={styles.label} colorScheme={colorScheme}>Checks aussetzen</CustomText>
                        <Switch value={preventCheckExecution} onValueChange={setPreventCheckExecution} />
                    </View>
                    <View style={styles.datePickerContainer}>
                        <CustomText style={styles.label} colorScheme={colorScheme}>Start</CustomText>
                        <CustomDateTimePicker value={serviceModeStartDate} onChange={setServiceModeStartDate}
                         style={styles.datePicker} colorScheme={colorScheme} />
                    </View>
                    <View style={styles.datePickerContainer}>
                        <CustomText style={styles.label} colorScheme={colorScheme}>Ende</CustomText>
                        <CustomDateTimePicker value={serviceModeEndDate} onChange={setServiceModeEndDate}
                         style={styles.datePicker} colorScheme={colorScheme} />
                    </View>
                    <CustomButtonWithCheckMark text='Wartungsmodus setzen' onPress={setServiceMode} secondary/>
                </View>
            </KeyboardAvoidingView>
        </Modal>
  )
}
const styles = StyleSheet.create({
    root:{
        width: '100%',
        flexDirection: 'column',
        paddingHorizontal: 20,
        paddingBottom: 40,
    },
    rootDark: {
        backgroundColor: '#111'
    },
    rootLight: {
        backgroundColor: '#fff'
    },
    modal: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    datePickerContainer: {
        width: '100%',
        alignSelf: 'center',
        flexDirection: 'row',
        marginBottom: 20
    },
    datePicker: {
        flexGrow: 8,
    },
    label: {
        flexGrow: 2,
        fontSize: 25,
        alignSelf: 'center',
    },
    header: {
        width: '100%',
        fontSize: 30,
        marginTop: 25
    },
    subHeader: {
        width: '100%',
        fontSize: 20,
        marginVertical: 15
    },
});

export default DeviceSerivceModeScreen
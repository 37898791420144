import AsyncStorage from '@react-native-async-storage/async-storage';
import axios, { AxiosResponse } from 'axios';
import { ICustomerSiteDeviceOverview } from '../../interfaces/WebServiceInterfaces';
import * as Device from 'expo-device';
import Constants from "expo-constants";
import Base64 from './Base64';
import { Platform } from 'react-native';

class WebServiceClient {
    private static instance: WebServiceClient;
    private url: string = '';
    private ticket : string = '';

    constructor() {
    }

    static getInstance(): WebServiceClient {
        WebServiceClient.instance = WebServiceClient.instance || new WebServiceClient();
        return WebServiceClient.instance;
    }

    async checkStoredInformation() : Promise<boolean> {
        this.ticket = '';
        const storedUrl = await AsyncStorage.getItem('webServiceUrl');
        const storedTicket = await AsyncStorage.getItem('connectionTicket');
        if(!storedUrl || !storedTicket){
            return false;
        }
        await axios.post(storedUrl + '/REST/IsValidTicket', {
            Ticket: storedTicket
        })
            .then((data) => data.data)
            .then((data) => {
                if (data.Status == 0) {
                    this.ticket = storedTicket;
                    this.url = storedUrl;
                }
        });
        if(this.ticket)
        {
            return true;
        }

        const storedUsername = await AsyncStorage.getItem('username');
        const storedPassword = await AsyncStorage.getItem('password');
        if(!storedUsername || !storedPassword){
            return false;
        }
        return this.login(storedUrl, storedUsername, storedPassword, 0);
    }
    
    async login(url: string, username: string, password: string, loginKind: number): Promise<boolean> {
        await axios.post(url + '/REST/Login', {
            Data: {
                Username: username,
                Password: password,
                LoginKind: loginKind,
                Application: Platform.OS == 'web' ? '{D9A075AA-992C-4BCE-A92D-FF2FC07E97D3}' /* Pro license */ : '{CEAE8D01-869A-495C-8038-663430CC951D}' /* Mobile license */,
                Device: Device.deviceName,
                AppVersion: Constants.manifest?.version
            }
        }).then((data) => data.data)
            .then(async (data) => {
                if (data.Status == 0) {
                    this.ticket = data.Result[0];
                    this.url = url;
                    AsyncStorage.setItem("connectionTicket", data.Result[0]);
                    AsyncStorage.setItem("webServiceUrl", url);
                    AsyncStorage.setItem("username", username);
                    AsyncStorage.setItem("password", password);
                    return Promise.resolve(true);
                } else if (loginKind == 0) {
                    return this.login(url, username, password, 2);
                } else {
                    throw data.Message;
                }
            });
        return this.ticket != '';
    }

    async logout() {
        await AsyncStorage.setItem("connectionTicket", "");
        await AsyncStorage.setItem("password", "");
    }

    private async callAsync<T> (method: string, data: any): Promise<AxiosResponse<T[]>>{
        if(!this.url)
        {
            this.url = await AsyncStorage.getItem("webServiceUrl") ?? "";
            this.ticket = await AsyncStorage.getItem("connectionTicket") ?? "";
        }
        return axios.post(this.url + "/REST/" + method,
            {
                Data: data,
                Ticket: this.ticket
            }).then((data) => data.data)
            .then((response) => {
                if(response.Status == 0){
                    return response.Result;
                } else if(response.Status == 2){
                    this.checkStoredInformation()
                    .then((storedValid)=> {
                        if(storedValid){
                            return this.callAsync(method, data)
                        }
                    })
                }
                else{
                    console.log(response.Message);
                }
            });
    }

    sendMobilePhoneToken = async (expoPushToken: string, deviceName: string, deviceOs: string) => {
        console.log(expoPushToken);
        return this.callAsync('SaveOrUpdateRiverbirdMobilePhonePushNotificationTokens', [{
            DeviceName: deviceName,
            DeviceOs: deviceOs,
            ExpoPushToken: expoPushToken,
            UserI3D: 0
        }]).catch((error) => console.log(error.toString()));
    }

    async getCustomers () : Promise<any> {
        return this.callAsync('SearchCustomersWithAssetManagementEntrys', '');
    }

    async getDeviceResultOverviews (filter: any) : Promise<any> {
        return this.callAsync('GetMonitoringCustomerDashboardOverviews', filter);
    }

    async saveMonCheckDeviceResult (result: any) : Promise<any> {
        return this.callAsync('SaveOrUpdateMonCheckDeviceResults', result);
    }

    async setDeviceForImmediateExecution (deviceI3Ds: Array<number>) : Promise<any> {
        return this.callAsync('AddMonitoringDevicesForImmediateExecution', deviceI3Ds);
    }

    async deleteMonCheckDeviceResults (filter: any) : Promise<any> {
        return this.callAsync('DeleteMonCheckDeviceResultsByFilter', filter);
    }

    async getDeviceOverviews (filter: any) : Promise<any> {
        return this.callAsync<ICustomerSiteDeviceOverview>('GetCustomerSiteDeviceOverviewByFilter', filter);
    }

    async getDeviceByI3D (i3d: number) : Promise<any> {
        return this.callAsync('GetAssetManagementDeviceByI3d', i3d);
    }

    async createDirectNowConnection (deviceGuid: string) : Promise<any> {
        return this.callAsync('CreateNewDirectNowConnection', deviceGuid);
    }

    async sendDirectNowCommand (command: any) : Promise<any> {
        return this.callAsync('SendNewDirectNowCommand', command);
    }

    async getDeviceTreeItemByFilter (filter: any) : Promise<any> {
        return this.callAsync('GetAssetManagementDeviceTreeItemsByFilter', filter);
    }

    async getMonChecksByFilter (filter: any) : Promise<any> {
        return this.callAsync('GetMonChecksByFilter', filter);
    }

    async getMonCheckDeviceResultsByFilter (filter: any) : Promise<any> {
        return this.callAsync('GetMonCheckDeviceResultsByFilter', filter);
    }

    async scheduleDeviceRestart (deviceI3D: number, restartDate: Date) : Promise<any> {
        return this.callAsync('UpdateAssetManagementDeviceRestart', {
            I3D: deviceI3D,
            DeviceRestartDateTime: restartDate.getMicrosoftJsonFormatted(),
            IsScheduleDeviceRestart: true,
            Message: "Neustart durch RiverSuite App",
            RestartKind: 0
        });
    }

    async getSupRemoInformation (filter: any) : Promise<any> {
        return this.callAsync('GetSupRemoInformation', filter);
    }
    async getSupRemoRiverSuiteProtocolLink (deviceI3D: int) : Promise<any> {
        return this.callAsync('GetSupRemoRiverSuiteProtocolLink', deviceI3D);
    }

    async getAntivirusThreats (filter: any) : Promise<any> {
        return this.callAsync('GetAntivirusThreatsByFilter', filter);
    }

    async saveOrUpdateAntivirusThreats (threats: any[]) : Promise<any> {
        return this.callAsync('SaveOrUpdateAntivirusThreats', threats);
    }

    async saveOrUpdateMonServiceModes (serviceModes: any[]) : Promise<any> {
        return this.callAsync('SaveOrUpdateMonServiceMode', serviceModes);
    }

    async validate2fa (mfaKey: any) : Promise<any> {
        if (!this.url)
        {
            this.url = await AsyncStorage.getItem('webServiceUrl') ?? "";
        }

        return axios.get(this.url + "/2fa/validate?code=" + mfaKey);
    }

    async GetUserWithImage () : Promise<any> {
        return this.callAsync('GetLoggedInUser', {})
        .then(async (data: any)=> {
            var images: any = await this.callAsync('GetEmployeeImage', data[0].I3D);
            data[0].EmployeeImage = this.arrayBufferToBase64(images[0].Image);
            return data[0];
        });
    }
    arrayBufferToBase64 = ( buffer: [] ) => {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return Base64.btoa( binary );
    }
}

export default WebServiceClient;
import { View, Text, StyleSheet, Pressable, Button, TouchableHighlight } from 'react-native'
import React from 'react'
import { FontAwesome } from '@expo/vector-icons'

const CustomCircularButton = ({iconName, onPress, colorScheme}: {iconName: React.ComponentProps<typeof FontAwesome>['name'], onPress: any, colorScheme: 'dark' | 'light'}) => {
    return (
        <TouchableHighlight style={[colorScheme == 'dark' ? styles.containerDark : styles.containerLight, styles.container]} underlayColor= {colorScheme == 'dark' ? '#3a3a3a' : '#e0e0e0'} onPress={onPress}>
                <View style={styles.icon} >
                    <ButtonIcon name={iconName} color={colorScheme == 'dark' ? '#fff' : '#2d2d2d' } />
                </View>
        </TouchableHighlight>
    )
}

  const styles = StyleSheet.create({
      container: {
          width: '100%',
          height: '100%',
          alignItems: 'center',
          alignContent: 'center',
          borderRadius: 50,
          display: 'flex',
          borderWidth: 1,
          flex: 1
      },
      containerLight: {
        borderColor: '#e0e0e0',
        backgroundColor: '#fff',
      },
      containerDark: {
        borderColor: '#3a3a3a',
        backgroundColor: '#2a2a2a',
      },
      icon:{
          alignSelf: 'center',
          top: '25%'
      }
  })

  function ButtonIcon(props: {
    name: React.ComponentProps<typeof FontAwesome>['name'];
    color: string;
  }) {
    return <FontAwesome size={30} {...props} />;
  }

export default CustomCircularButton
import { View, Image, Linking, FlatList, TouchableOpacity } from 'react-native'
import React from 'react'
import CustomText from '../../components/CustomText'
import useColorScheme from '../../hooks/useColorScheme'
import { getUsedLicenses } from '../../components/util/LicenseHelper';

const AboutScreen = () => {
  const colorScheme = useColorScheme();
  const licenses = getUsedLicenses();
  return (
    <View style={{ padding: '2%', paddingBottom: '25%' }}>
      <CustomText colorScheme={colorScheme} style={{ fontSize: 22 }}>RiverSuite Mobile</CustomText>
      <CustomText colorScheme={colorScheme} style={{ opacity: 0.8 }}>© Riverbird GmbH 2022</CustomText>
      <View style={{ height: 1, width: '100%', backgroundColor: '#888', marginVertical: 5, opacity: 0.6 }} />
      <CustomText colorScheme={colorScheme} style={{ fontSize: 22 }}>Lizenzen</CustomText>
      <FlatList data={licenses} renderItem={({ item }) => (
        <TouchableOpacity style={{ flexDirection: 'row', marginVertical: 5 }} onPress={()=> Linking.openURL(item.licenseUrl)}>
          <Image
            style={{ width: 50, height: 50, borderRadius: 25 }}
            source={{ uri: item.image }}
          />
          <View style= {{marginLeft: 10}}>
            <CustomText colorScheme={colorScheme} style={{fontSize: 16}}>{item.name}</CustomText>
            <CustomText colorScheme={colorScheme} style={{opacity: 0.8}}>{`${item.version} • ${item.licenses} • ${item.username}`}</CustomText>
          </View>
        </TouchableOpacity>
      )}
      />
    </View>
  )
}

export default AboutScreen
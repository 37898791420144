import {View, Text, FlatList, TouchableOpacity, StyleSheet, StyleProp, ViewStyle} from 'react-native'
import React, { useState } from 'react'
import CustomText from '../CustomText'
import { AntDesign } from '@expo/vector-icons';
import CustomInput from '../CustomInput';

const CustomComboBox = ({ closedText, uniqueKey, selectedItems, setSelectedItems, items, displayMember, colorScheme, flatListProps, style  }: { closedText: string, uniqueKey: string, selectedItems: any[], setSelectedItems: Function, items: any[], displayMember: string, colorScheme: 'dark' | 'light', flatListProps?: any, style?: StyleProp<ViewStyle> | undefined;  }) => {

    const [comboBoxExpanded, setComboBoxExpanded] = useState(false);
    const [filterText, setFilterText] = useState('');
    const filteredItems = items.filter((x: any) => x[uniqueKey].toString().includes(filterText) || x[displayMember].toLowerCase().includes(filterText.toLowerCase())).sort(function(a, b) {
        if (a[displayMember].toLowerCase() < b[displayMember].toLowerCase()) {
          return -1;
        }
        if (a[displayMember].toLowerCase() > b[displayMember].toLowerCase()) {
          return 1;
        }
      
        return 0;
      });
    function addOrRemove(item: any){
        if(selectedItems.some((x) => x[uniqueKey] == item[uniqueKey])){
            setSelectedItems(selectedItems.filter((x) => x[uniqueKey] != item[uniqueKey]));
            item.selected = false;
        }else{
            const filtered = selectedItems.slice();
            filtered.push(item);
            setSelectedItems(filtered);
        }
    }
    function getRow(item: any) {
        return (
            <TouchableOpacity style={styles.listItem} onPress={()=> addOrRemove(item)}>
                <CustomText style={{width: '95%'}} colorScheme={colorScheme}>{item[displayMember] + ' (' + item[uniqueKey] + ')'}</CustomText>
                {selectedItems.some((x: any) => x[uniqueKey] == item[uniqueKey]) && 
                <AntDesign name='check' color={colorScheme == 'dark' ? '#eee' : '#111'}/>}
            </TouchableOpacity>
        )
    }
    return (
        <View style={[styles.root, colorScheme == 'dark' ? styles.rootDark : styles.rootLight, style]}>
            {!comboBoxExpanded && <TouchableOpacity onPress={()=> setComboBoxExpanded(true)}>
                <View style={styles.row}>
                    <CustomText style={{width: '95%'}} colorScheme={colorScheme}>{selectedItems.length > 0 ? selectedItems.map((x: any) => x[displayMember]).join(", ") : closedText}</CustomText>
                    <AntDesign name='caretdown' color={colorScheme == 'dark' ? '#eee' : '#111'}/>
                </View>
            </TouchableOpacity>}
            {comboBoxExpanded &&
                <View>
                    <TouchableOpacity onPress={() => setComboBoxExpanded(false)}>
                        <View style={styles.row}>
                            <View style={{width: '90%'}}>
                                <CustomInput clearButtonMode='always' placeholder='Suche' setValue={setFilterText} value={filterText} />
                            </View>
                            <View style={{justifyContent: 'center', marginHorizontal: 15}}>
                            <AntDesign name='caretup' color={colorScheme == 'dark' ? '#eee' : '#111'} />
                            </View>
                        </View>
                    </TouchableOpacity>
                    <FlatList style={{maxHeight: 200}}
                    data={filteredItems}
                    keyExtractor={item => item[uniqueKey]}
                    renderItem={rowData => getRow(rowData.item)}
                    {...flatListProps}
                    nestedScrollEnabled
                />
                </View>}
                
        </View>
    )
}
const styles = StyleSheet.create({
    root: {
        borderRadius: 5,
        width: '100%',
        minHeight: 30,
        alignContent: 'center',
        justifyContent: 'center',
        padding: 15
    },
    row: {
        width: '100%',
        flexDirection: 'row',
    },
    rootDark: {
        backgroundColor: '#333'
    },
    rootLight: {
        backgroundColor: '#eee'
    },
    listItem: {
        flexDirection: 'row',
        marginVertical: 5
    },
})
export default CustomComboBox
import {
    FlatList,
    KeyboardAvoidingView,
    Linking,
    Platform,
    StyleSheet,
    Switch,
    Text,
    View,
    ViewStyle
} from 'react-native'
import Modal from "react-native-modal";
import React, {useEffect, useState} from 'react'
import useColorScheme from '../hooks/useColorScheme';
import {Entypo, Feather, FontAwesome} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import CustomInput from '../components/CustomInput';
import {useActionSheet} from '@expo/react-native-action-sheet';
import * as Haptics from 'expo-haptics';
import WebServiceClient from '../components/util/WebServiceClient';
import CustomText from '../components/CustomText';
import {ICustomerSiteDeviceOverview, IDeviceResultOverview} from '../interfaces/WebServiceInterfaces';
import * as Clipboard from 'expo-clipboard';
import DeviceRestartScreen from './DeviceRestartScreen';
import {EventRegister} from 'react-native-event-listeners';
import DeviceSerivceModeScreen from './DeviceServiceModeScreen';
import CustomListItem from '../components/CustomListItem';
import CustomButton from '../components/CustomButton';
import CustomCircularButton from '../components/CustomCircularButton/CustomCircularButton';
import NotificationHandler from '../components/util/NotificationHandler';
import {DrawerContentScrollView} from "@react-navigation/drawer";
import {DataTable} from 'react-native-paper';
import WebServiceEnumConverter from "../components/util/WebServiceEnumConverter";
import DeviceDetailScreen from "./DeviceDetailScreen";

const DevicesOverviewScreen = () => {
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState<ICustomerSiteDeviceOverview[]>([]);
    const [filteredDevices, setFilteredDevices] = useState<ICustomerSiteDeviceOverview[]>([]);
    const [searchText, setSearchText] = useState('');
    const [showRestartModal, setShowRestartModal] = useState(false);
    const [showServiceModeModal, setShowServiceModeModal] = useState(false);
    const [pressedDevice, setPressedDevice] = useState<ICustomerSiteDeviceOverview | undefined>({ I3D: 0, containsAll: false});
    const [lastRefresh, setLastRefresh] = useState(new Date());
    const { showActionSheetWithOptions } = useActionSheet();
    const colorScheme = useColorScheme();
    const navigation = useNavigation();
    const webServiceClient = WebServiceClient.getInstance();
    const [showFilter, setShowFilter] = useState(false);
    const [filterSuccess, setFilterSuccess] = useState(true);
    const [filterWarning, setFilterWarning] = useState(true);
    const [filterError, setFilterError] = useState(true);
    const [filterOfflineWorkstations, setFilterOfflineWorkstations] = useState(false);
    const notificationHandler = NotificationHandler.getInstance(navigation);
    const web = Platform.OS === "web";
    const [selectedDevice, setSelectedDevice] = useState<ICustomerSiteDeviceOverview | undefined>(undefined);


    const refresh = async () => {
        const checkResults = [];
        if(filterSuccess){
            checkResults.push(0);
        }
        if(filterError){
            checkResults.push(1);
        }
        if(filterWarning){
            checkResults.push(2);
        }
        setLoading(true);
        webServiceClient.getDeviceOverviews({
            CheckResults: filterSuccess && filterWarning && filterError ? null : checkResults,
        })
            .then((data) => {
                data.forEach((device: any) => {
                    device.IsOnline = device.LastContact && Math.round((lastRefresh.valueOf() - device.LastContact.getDateFromMicrosoftJsonFormatted().valueOf()) / 1000 / 60) < 5
                });
                const filtered = data.filter((x: any) => !(filterOfflineWorkstations && x.DeviceClass == 'Workstation' && !x.IsOnline));
                setDevices(filtered);
            })
            .finally(() => {
                setLoading(false);
                setLastRefresh(new Date());
            });
    }

    const filter = () => {
        if(searchText == ""){
            setFilteredDevices(devices);
            return;
        }
        devices.forEach(element => {
            element.containsAll = true;
        });
        if(filterOfflineWorkstations){
            devices
            .filter(x => x.DeviceClass == 'Workstation' && !x.IsOnline)
            .forEach(element => {
                element.containsAll = false;
            });
        }
        searchText.toLowerCase().split(" ").forEach(search => {
            devices.forEach(item => {
                if(!(item.CustomerName?.toLowerCase().includes(search)
                || item.DeviceName?.toLowerCase().includes(search)
                || item.CustomerSiteName?.toLowerCase().includes(search)
                || item.DeviceClass?.toLowerCase().includes(search)
                || item.LastUser?.toLowerCase().includes(search))){
                    item.containsAll = false;
                }
            });
        });
        setFilteredDevices(devices.filter(x => x.containsAll));
    }

    useEffect(() => {
        refresh();
    }, [])

    useEffect(() => {
        filter();
    }, [devices])

    useEffect(() => {
        filter();
    }, [searchText])

    const deviceLongClicked = (item: ICustomerSiteDeviceOverview) => {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
        showActionSheetWithOptions({
            options: ["Cancel", "Starte SupRemo Verbindung" , "Gerät neustarten", "Wartungsmodus einrichten"],
            cancelButtonIndex: 0,
            destructiveButtonIndex: 2,
            userInterfaceStyle: colorScheme,
        },
            buttonIndex => {
                setPressedDevice(item);
                if (buttonIndex === 0) {
                    return;
                } else if (buttonIndex === 1) {
                    startSupRemoConnection(item);
                } else if (buttonIndex === 2) {
                    setShowRestartModal(true);
                }  else if (buttonIndex === 3) {
                    setShowServiceModeModal(true);
                } 
            })
    }

    const getStyles = (styleMobile: ViewStyle, styleWeb: ViewStyle) : ViewStyle[] => {
        if (Platform.OS == 'web')
            return  [styleMobile, styleWeb]
        return [styleMobile]
    }

    const startSupRemoConnection = (device: ICustomerSiteDeviceOverview) => {
      if(!device.SupRemoInstalled){
        return;
      }
      webServiceClient.getSupRemoInformation({ DeviceI3Ds: [device.DeviceI3D] })
      .then((data) => {
        Clipboard.setString(data[0].RemotePassword);
        return data[0].RemoteID;
      })
      .then((data)=> Linking.openURL("supremo-remote-desktop://supremoId=" + data))
      .then(()=> notificationHandler.pushLocalNotification("SupRemo Passwort", "Passwort in die Zwischenablage kopiert!"));
    }

    return (
        web ?
            <DrawerContentScrollView ref={data => {
                EventRegister.removeEventListener('deviceTabIconPressed');
                EventRegister.addEventListener('deviceTabIconPressed', () => {
                    data?.scrollTo(0, 0, true);
                });
            }}
                                     stickyHeaderIndices={[0]}>
                <View style={[{alignSelf: 'stretch'}, webStyles.root]}>
                    <View style={[webStyles.horizontal, webStyles.horizontalSpacing, webStyles.root, {alignItems:'stretch', justifyContent: 'space-between'}]}>
                        {/*SearchBar*/}
                        <CustomInput placeholder='Suche' value={searchText} setValue={setSearchText} clearButtonMode='always'
                                     style={webStyles.customerSearchBar}/>

                        <View style={[webStyles.horizontal, webStyles.root, webStyles.alignRight]}>

                            {/*FilterBar*/}
                            <View style={webStyles.filterSettingsContainer}>
                                <FontAwesome style={styles.filterIcon} name='times-circle' size={24}
                                             color="#e74c3c"/>
                                <CustomText style={webStyles.filterText} colorScheme={colorScheme}>Fehler</CustomText>
                                <Switch style={styles.filterSwitch} value={filterError} onValueChange={setFilterError}/>
                            </View>
                            <View style={webStyles.filterSettingsContainer}>
                                <FontAwesome style={styles.filterIcon} name='warning' size={24}
                                             color="#f1c40f"/>
                                <CustomText style={webStyles.filterText} colorScheme={colorScheme}>Warnung</CustomText>
                                <Switch style={styles.filterSwitch} value={filterWarning}
                                        onValueChange={setFilterWarning}/>
                            </View>
                            <View style={webStyles.filterSettingsContainer}>
                                <FontAwesome style={styles.filterIcon} name='check-circle' size={24}
                                             color="#2ecc71"/>
                                <CustomText style={webStyles.filterText}
                                            colorScheme={colorScheme}>Erfolgreich</CustomText>
                                <Switch style={styles.filterSwitch} value={filterSuccess}
                                        onValueChange={setFilterSuccess}/>
                            </View>
                            <View style={webStyles.filterSettingsContainer}>
                                <Feather style={styles.filterIcon} name='wifi-off' size={24}
                                         color="#7F8C8D"/>
                                <CustomText style={webStyles.filterText} colorScheme={colorScheme}>Offline Workstations
                                    ausblenden</CustomText>
                                <Switch style={styles.filterSwitch} value={filterOfflineWorkstations}
                                        onValueChange={setFilterOfflineWorkstations}/>
                            </View>
                            <View style={webStyles.filterSettingsContainer}>
                                <CustomButton text='Anwenden' onPress={() => {
                                    refresh();
                                }} secondary/>
                            </View>
                        </View>

                    </View>
                </View>

                <View style={[styles.root, webStyles.root]}>
                    <DataTable>
                        <DataTable.Header style={webStyles.headerBar}>
                            <DataTable.Title>Typ</DataTable.Title>
                            <DataTable.Title>Gerät</DataTable.Title>
                            <DataTable.Title>Kunde</DataTable.Title>
                            <DataTable.Title>Standort</DataTable.Title>
                        </DataTable.Header>
                        {filteredDevices.map(device => {
                            return(
                                <View>
                                    <DataTable.Row onPress={() => {pressedDevice == device ? setPressedDevice(undefined) : setPressedDevice(device)}}
                                                   key={device.I3D + "device" + device.DeviceI3D}
                                                   style={[device == pressedDevice && {backgroundColor: '#c7ecee'}]}>
                                        <DataTable.Cell>{device.DeviceClass}</DataTable.Cell>
                                        <DataTable.Cell>{device.DeviceName}</DataTable.Cell>
                                        <DataTable.Cell>{device.CustomerName}</DataTable.Cell>
                                        <DataTable.Cell>{device.CustomerSiteName}</DataTable.Cell>

                                    </DataTable.Row>
                                    {device == pressedDevice && (
                                        <View>
                                            <DeviceDetailScreen deviceInfo={device}/>
                                        </View>
                                    )}
                                </View>
                            )
                        })}
                    </DataTable>
                </View>
            </DrawerContentScrollView>
            :
            <View style={styles.root}>
            <CustomInput placeholder='Suche' value={searchText} setValue={setSearchText} clearButtonMode='always'></CustomInput>
            <FlatList style={getStyles(styles.list, webStyles.list)}
                keyExtractor={(f) => f.I3D.toString()}
                numColumns={Platform.OS == "web" ? 4 : 0}
                data={filteredDevices}
                initialNumToRender={10}
                ref={data => {
                    EventRegister.removeEventListener('deviceTabIconPressed');
                    EventRegister.addEventListener('deviceTabIconPressed', () => {
                        data?.scrollToOffset({ animated: true, offset: 0 });
                    });
                }}
                renderItem={({ item }) => (
                    <CustomListItem onPress={() => { navigation.navigate('DeviceDetail', item) }} onLongPress={() => deviceLongClicked(item)} colorScheme={colorScheme} style={webStyles.alertContainer}>
                    
                            <FontAwesome size={30}
                                style={styles.deviceIcon}
                                name={item.DeviceClass == 'Server' ? 'server' : 'desktop'}
                                color={colorScheme == 'dark' ? '#fff' : '#3a3a3a'} />
                            <Entypo style={styles.deviceOnlineOverlay}
                                size={20}
                                name='dot-single' color={item.IsOnline ? '#2ecc71' : '#e74c3c'} />
                            <View style={{ flex: 7, flexGrow: 7, flexShrink: 1}}>
                                <View style={{width: '100%', flexDirection: 'row', flex: 2, justifyContent: "space-between"}}>
                                    <CustomText colorScheme={colorScheme} style={{opacity: 0.8, fontSize: 12, flex: 3}}>{item.DeviceClass}</CustomText>
                                    { item.LastCheckResult != null ? 
                                    <View style={[{ borderRadius: 3, paddingHorizontal: 4, flex: 2}, item.LastCheckResult == 0 ? {backgroundColor: "#2ecc71"} : item.LastCheckResult == 1 ? {backgroundColor: "#e74c3c"} : {backgroundColor: "#f1c40f"}]}>
                                        <Text style={{fontSize: 12, color: '#fff', alignSelf: 'center'}}>{item.LastCheckResult == 0 ? "Alles in Ordnung" : item.LastCheckResult == 1 ? "Offene Fehler" : "Offene Warnungen" }</Text>
                                    </View> : <></>}
                                </View>
                                <CustomText colorScheme={colorScheme} style={styles.containerHeader}>{item.DeviceName}</CustomText>
                                <CustomText colorScheme={colorScheme}>{item.CustomerName}</CustomText>
                                <CustomText colorScheme={colorScheme}>{item.CustomerSiteName}</CustomText>
                            </View>
                    </CustomListItem>
                )}
                refreshing={loading}
                onRefresh={refresh} />
                <Modal style={styles.filterModal} isVisible={showFilter} onBackdropPress={() => setShowFilter(false)}>
                <KeyboardAvoidingView behavior='position'>
                    <View style={[styles.filterRoot, colorScheme == 'dark' ? styles.filterRootDark : styles.filterRootLight]}>
                        <CustomText colorScheme={colorScheme} style={styles.filterHeader}>Filteroptionen</CustomText>
                        <View style={styles.filterSettingsContainer}>
                            <Switch style={styles.filterSwitch} value={filterError} onValueChange={setFilterError} />
                            <FontAwesome style={styles.filterIcon} name='times-circle' size={24}
                                color="#e74c3c" />
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Fehler</CustomText>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <Switch style={styles.filterSwitch} value={filterWarning} onValueChange={setFilterWarning} />
                            <FontAwesome style={styles.filterIcon} name='warning' size={24}
                                color="#f1c40f" />
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Warnung</CustomText>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <Switch style={styles.filterSwitch} value={filterSuccess} onValueChange={setFilterSuccess} />
                            <FontAwesome style={styles.filterIcon} name='check-circle' size={24}
                                color="#2ecc71" />
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Erfolgreich</CustomText>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <Switch style={styles.filterSwitch} value={filterOfflineWorkstations} onValueChange={setFilterOfflineWorkstations} />
                            <Feather style={styles.filterIcon} name='wifi-off' size={24}
                                color="#eee" />
                            <CustomText style={styles.filterText} colorScheme={colorScheme}>Offline Workstations ausblenden</CustomText>
                        </View>
                        <View style={styles.filterSettingsContainer}>
                            <CustomButton text='Anwenden' onPress={() => { setShowFilter(false); refresh(); }} secondary />
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </Modal>
            <View style={styles.filterButton}>
                <CustomCircularButton iconName={showFilter ? 'times' : 'filter'}
                    colorScheme={colorScheme}
                    onPress={() => {
                        setShowFilter(!showFilter);
                    }} />
            </View>
            <DeviceRestartScreen show={showRestartModal} device={pressedDevice} pressCallback={() => setShowRestartModal(false)} />
            <DeviceSerivceModeScreen show={showServiceModeModal} device={pressedDevice} pressCallback={() => setShowServiceModeModal(false)} />
        </View>
    )
}

const webStyles = StyleSheet.create({
    root: {
        backgroundColor: '#F5F5F5'
    },
    headerBar: {
        backgroundColor: '#F5F5F5'
    },
    filterSettingsContainer: {
        flexDirection: 'row',
        marginVertical: 3
    },
    filterText: {
        fontSize: 18,
        alignSelf: 'center',
        marginHorizontal: 15
    },
    alignRight:{
        alignItems: 'center',
        alignSelf: 'baseline',
        alignContent: 'flex-end',
        justifyContent: 'flex-end',
        flex: 6
    },
    alertContainer: {
        flex: 1,
        marginHorizontal: 5,
        alignContent: 'stretch',
        alignItems: "stretch",
        alignSelf: "stretch"
    },
    list: {
        flex: 1,
        flexDirection: "column",
        flexWrap: "wrap",
        alignSelf: "stretch"
    },
    horizontal:{
        flexDirection: "row",
        flexWrap: "wrap"
    },
    horizontalSpacing:{
        marginHorizontal: "2%"
    },
    customerSearchBar:{
        alignSelf: 'flex-start',
        flex: 4,
    },
});

const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
        paddingHorizontal: '2%'
    },
    containerHeader: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    list: {
        width: '100%',
        height: '90%'
    },
    deviceIcon: {
        width: 35,
        maxHeight: 100,
        alignSelf: 'center',
        marginHorizontal: 15,
        flex: 1
    },
    deviceOnlineOverlay: {
        position: 'absolute',
        bottom: 0,
        left: 40
    },
    filterRoot: {
        width: '100%',
        flexDirection: 'column',
        paddingHorizontal: 20,
        paddingBottom: 40,
    },
    filterRootDark: {
        backgroundColor: '#111'
    },
    filterRootLight: {
        backgroundColor: '#fff'
    },
    filterModal: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    filterHeader: {
        fontSize: 24,
        textAlign: 'center',
        marginVertical: 15
    },
    filterSettingsContainer: {
        flexDirection: 'row',
        marginVertical: 10
    },
    filterText: {
        fontSize: 18,
        alignSelf: 'center',
        marginHorizontal: 15
    },
    filterSwitch: {
        marginRight: 15
    },
    filterIcon: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    },
    filterButton: {
        position: 'absolute',
        bottom: '5%',
        right: '10%',
        width: 60,
        height: 60,
    },
})

export default DevicesOverviewScreen
import {Platform, Text, Image, StyleSheet, useWindowDimensions, KeyboardAvoidingView, ViewStyle} from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/images/riverbird.png';
import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';
import { useNavigation } from '@react-navigation/native';
import * as LocalAuthentication from 'expo-local-authentication';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import WebServiceClient from '../../components/util/WebServiceClient';
import NotificationHandler from '../../components/util/NotificationHandler';

const SignInScreen = (props: any) => {
    const [url, setUrl] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { height } = useWindowDimensions();
    const navigation = useNavigation();
    const webserviceClient = WebServiceClient.getInstance();
    
    const notificationHandler = NotificationHandler.getInstance(navigation);

    React.useEffect(()=>{
        notificationHandler.registerForPushNotifications();
      }, [props])

    useEffect(() => { checkStoredCredentials() }, [props])

    const checkStoredCredentials = async () => {
        const storedUrl = await AsyncStorage.getItem('webServiceUrl');
        const storedUsername = await AsyncStorage.getItem('username');
        setUrl(storedUrl ?? '');
        setUsername(storedUsername ?? '');
        if(Platform.OS == 'web')
        {
            return;
        }
        LocalAuthentication.authenticateAsync().then((data)=>{
            if(!data.success){
                return;
            }
            webserviceClient.checkStoredInformation()
            .then(async (data) => {
                if(data){
                    navigateHome();
                }
            }).catch((error) => setError(error.toString()));
        })
    };
    
    const login = (url: string, username: string, password: string) => {
        setError('');
        webserviceClient.login(url, username, password, 0)
        .then(async (data)=> {
            if(data){
                await sendMobilePhoneToken();
                navigateHome();
            }
        }).catch((error) => setError(error.toString()));        
    }

    const sendMobilePhoneToken = async () => {
        if(Platform.OS == 'web'){
            return;
        }
        return Notifications.getExpoPushTokenAsync().then((data) => data.data)
        .then((data) => webserviceClient.sendMobilePhoneToken(data, Device.deviceName ?? 'Unknown Devicename', Device.osName + ' ' + Device.osVersion))
        .catch();
    }

    const onSignInPressed = () => {
        try{
            login(url, username, password);
        }catch (e: any)
        {
            setError(e.toString())
        }
    }
    const getStyles = (styleMobile: ViewStyle, styleWeb: ViewStyle) : ViewStyle[] => {
        if (Platform.OS == 'web')
            return  [styleMobile, styleWeb]
        return [styleMobile]
    }

    const navigateHome = () => {
        navigation.reset({
            index: 0,
            routes: [{ name: 'Root' }],
        });
    }
    return (
        <KeyboardAvoidingView style={getStyles(styles.root, webStyles.root)} behavior='height'>
            <Image source={Logo} style={[styles.logo, { height: height * 0.3 }]} resizeMode="contain" />
            <KeyboardAvoidingView style={styles.inputContainer} behavior='position'>
                <CustomInput value={url} setValue={setUrl} placeholder='WebService URL' clearButtonMode='always' keyboardType='url'  enterPressed={onSignInPressed}/>
                <CustomInput value={username} setValue={setUsername} placeholder='Username' clearButtonMode='always'  enterPressed={onSignInPressed}/>
                <CustomInput value={password} setValue={setPassword} placeholder='Password' secureTextEntry clearButtonMode='always' enterPressed={onSignInPressed} />
                <CustomButton onPress={onSignInPressed} text='LOGIN' />
            </KeyboardAvoidingView>
            <Text style={styles.error}>{error}</Text>
        </KeyboardAvoidingView>
    )
}
const webStyles = StyleSheet.create({
    root: {
        alignItems: 'center',
        alignSelf: 'center',
        padding: 20,
        width: '25%'
    },
});

const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
        padding: 20
    },
    logo: {
        width: '70%',
        maxWidth: 500,
        maxHeight: 300,
        marginTop: 20
    },
    error: {
        color: '#eb4f4f'
    },
    inputContainer: {
        width: '100%'
    },
})

export default SignInScreen
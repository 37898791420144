import licenses from '../../assets/licenses.json';
export type LicensesType = {
    repository: string;
    licenseUrl: string;
    parents: string;
    key: string;
    name: string;
    image: string | undefined;
    userUrl: string | undefined;
    username: string | null;
    licenses: string;
    version: string;
  };
  
  // ! It is not certain all these fields will be present
  export type jsonData = {
    licenses: string;
    repository: string;
    licenseUrl: string;
    parents: string;
  };
function extractNameFromGithubUrl(url: string): string | null {
    const reg = /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i;
    const components = reg.exec(url);

    if (components && components.length > 5) {
        return components[5];
    }

    return null;
}
export const getUsedLicenses = (): LicensesType[] => Object.entries(licenses).map(
    ([_key, value]) => {
        const { licenses, ...license } = value;

        const key = _key.charAt(0) === "@" ? _key.substring(1) : _key; // Prevents bug on next line if package starts with @
        const [name, version] = key.split("@");

        // ! It is assummed that the repository field is never undefined
        let username =
            (license.repository == null
                ? extractNameFromGithubUrl(license.licenseUrl)
                : extractNameFromGithubUrl(license.repository)) ?? "";

        let userUrl;
        let image;

        if (username) {
            username = username.charAt(0).toUpperCase() + username.slice(1);
            image = `http://github.com/${username}.png`;
            userUrl = `http://github.com/${username}`;
        }

        return {
            key,
            name,
            image,
            userUrl,
            username,
            licenses, // Previously this used licenses.slice(0, 405), I don't exactly know why (Prevent overflow?) so I removed it.
            version,
            ...license,
        };
    }
);

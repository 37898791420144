import { View, StyleSheet, KeyboardAvoidingView } from 'react-native'
import React, { useState } from 'react'
import DateTimePicker from '@react-native-community/datetimepicker';
import useColorScheme from '../hooks/useColorScheme';
import CustomText from '../components/CustomText';
import WebServiceClient from '../components/util/WebServiceClient';
import CustomButtonWithCheckMark from '../components/CustomButtonWithCheckMark/CustomButtonWithCheckMark';
import Modal from "react-native-modal";
import CustomDateTimePicker from '../components/CustomDateTimePicker';

const DeviceRestartScreen = ({device, show, pressCallback}: {device: any, show: boolean, pressCallback: Function}) => {
    const [restartDate, setRestartDate] = useState(new Date());
    const colorScheme = useColorScheme();
    const webServiceClient = WebServiceClient.getInstance();

    const restartDevice = () => {
        webServiceClient.scheduleDeviceRestart(device.DeviceI3D, restartDate)
        .then(()=> setTimeout(()=> pressCallback(), 1000))
    }
    return (
        <Modal isVisible={show} animationIn='slideInUp' coverScreen={false} style={styles.modal} onBackdropPress={()=>pressCallback()} avoidKeyboard>
            <KeyboardAvoidingView behavior='height'>
                <View style={[colorScheme == 'dark' ? styles.rootDark : styles.rootLight ,styles.root]}>
                    <CustomText style={styles.header} colorScheme={colorScheme}>Neustart konfigurieren</CustomText>
                    <CustomText style={styles.subHeader} colorScheme={colorScheme}>{device.DeviceName}</CustomText>
                    <View style={styles.datePickerContainer}>
                        <CustomText style={styles.label} colorScheme={colorScheme}>Zeitpunkt</CustomText>
                        <CustomDateTimePicker value={restartDate} onChange={setRestartDate}
                            style={styles.datePicker} colorScheme={colorScheme}/>
                    </View>
                    <CustomButtonWithCheckMark text='Neustart ausführen' onPress={restartDevice} secondary/>
                </View>
            </KeyboardAvoidingView>
        </Modal>
  )
}
const styles = StyleSheet.create({
    root:{
        width: '100%',
        flexDirection: 'column',
        paddingHorizontal: 20,
        paddingBottom: 40,
    },
    rootDark: {
        backgroundColor: '#111'
    },
    rootLight: {
        backgroundColor: '#fff'
    },
    modal: {
        justifyContent: 'flex-end',
        margin: 0,
        flex: 1
    },
    datePickerContainer: {
        width: '100%',
        alignSelf: 'center',
        flexDirection: 'row',
        marginBottom: 20
    },
    datePicker: {
        flexGrow: 8,
    },
    label: {
        flexGrow: 2,
        fontSize: 25,
        alignSelf: 'center',
    },
    header: {
        width: '100%',
        fontSize: 30,
        marginTop: 25
    },
    subHeader: {
        width: '100%',
        fontSize: 20,
        marginVertical: 15
    },
});

export default DeviceRestartScreen
import { View, StyleSheet, KeyboardAvoidingView, Platform, Dimensions } from 'react-native'
import React, { useState } from 'react'
import DateTimePicker from '@react-native-community/datetimepicker';
import useColorScheme from '../hooks/useColorScheme';
import CustomText from '../components/CustomText';
import WebServiceClient from '../components/util/WebServiceClient';
import CustomButtonWithCheckMark from '../components/CustomButtonWithCheckMark/CustomButtonWithCheckMark';
import Modal from "react-native-modal";
import CustomInput from '../components/CustomInput';
import { IAlertDetail } from '../interfaces/WebServiceInterfaces';
import CustomDateTimePicker from '../components/CustomDateTimePicker';

const SetCheckResultOkScreen = ({alert, show, pressCallback}: {alert: IAlertDetail, show: boolean, pressCallback: Function}) => {
    const [checkResultDate, setCheckResultDate] = useState(new Date());
    const [reason, setReason] = useState('');
    const colorScheme = useColorScheme();
    const webServiceClient = WebServiceClient.getInstance();
    if(!alert){
        alert = {};
    }

    const setCheckOk = () => {
        webServiceClient.saveMonCheckDeviceResult([{
            CheckI3D: alert.CheckI3D,
            DeviceI3D: alert.DeviceI3D,
            ExecutedAt: checkResultDate.getMicrosoftJsonFormatted(),
            CheckOutput: '{"Message":"' + reason + '"}',
            BundleI3D: alert.BundleI3D,
            CheckResult: 0
        }]).then(() => setTimeout(() => pressCallback(), 1000))
    }
    return (
        <Modal isVisible={show} animationIn='slideInUp' coverScreen={false} style={styles.modal} onBackdropPress={() => pressCallback()} avoidKeyboard>
            <View style={[colorScheme == 'dark' ? styles.rootDark : styles.rootLight, styles.root]}>
                <CustomText style={styles.header} colorScheme={colorScheme}>Checkergebnis auf Ok setzen</CustomText>
                <CustomText style={styles.subHeader} colorScheme={colorScheme}>{alert.DeviceName} - {alert.CheckName}</CustomText>
                <CustomInput value={reason} setValue={setReason} clearButtonMode='always' placeholder='Grund' />
                <View style={styles.datePickerContainer}>
                    <CustomText style={styles.label} colorScheme={colorScheme}>Zeitpunkt</CustomText>
                    <CustomDateTimePicker value={checkResultDate} onChange={setCheckResultDate}
                        style={styles.datePicker} colorScheme={colorScheme} />
                </View>
                <CustomButtonWithCheckMark text='Check auf Ok setzen' onPress={setCheckOk} secondary />
            </View>
        </Modal>
    )
}
const mobileStyles = StyleSheet.create({
    root:{
        width: '100%',
        flexDirection: 'column',
        paddingHorizontal: 20,
    },
    rootDark: {
        backgroundColor: '#111'
    },
    rootLight: {
        backgroundColor: '#fff'
    },
    modal: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    datePickerContainer: {
        width: '100%',
        alignSelf: 'center',
        flexDirection: 'row',
        marginBottom: 20
    },
    datePicker: {
        flexGrow: 8,
    },
    label: {
        flexGrow: 2,
        fontSize: 25,
        alignSelf: 'center',
    },
    header: {
        width: '100%',
        fontSize: 27,
        marginTop: 25
    },
    subHeader: {
        width: '100%',
        fontSize: 15,
        marginVertical: 15
    },
});
const webStyles = StyleSheet.create({
    root:{
        width: '100%',
        flexDirection: 'column',
        paddingHorizontal: 20,
        height: 350,
        marginTop: 550
    },
    rootDark: {
        backgroundColor: '#111'
    },
    rootLight: {
        backgroundColor: '#fff'
    },
    modal: {
        justifyContent: 'center',
        margin: 0,
        width: '30%',
        alignSelf: 'center',
        height: '350px'
    },
    datePickerContainer: {
        width: '100%',
        alignSelf: 'center',
        flexDirection: 'row',
        marginBottom: 20
    },
    datePicker: {
        flexGrow: 8,
    },
    label: {
        flexGrow: 2,
        fontSize: 25,
        alignSelf: 'center',
    },
    header: {
        width: '100%',
        fontSize: 27,
        marginTop: 25
    },
    subHeader: {
        width: '100%',
        fontSize: 15,
        marginVertical: 15
    },
});
const styles = Platform.OS == 'web' ? webStyles : mobileStyles;

export default SetCheckResultOkScreen
import { View, Text, StyleSheet } from 'react-native'
import React, { useEffect, useState } from 'react'
import WebServiceClient from '../components/util/WebServiceClient'
import Modal from "react-native-modal";
import CustomText from '../components/CustomText';
import useColorScheme from '../hooks/useColorScheme';
import CustomButtonWithCheckMark from '../components/CustomButtonWithCheckMark/CustomButtonWithCheckMark';
import CustomButton from '../components/CustomButton';
import { useNavigation } from '@react-navigation/native';

const TwoFactorConfirmationScreen = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const webserviceClient = WebServiceClient.getInstance();
    const navigation = useNavigation();
    const twoFactorData = props.route.params;
    const colorScheme = useColorScheme();
    let modalWindow: Modal | null;
    useEffect(()=>{
      setShowModal(true);
    }, [props]);
    const confirm2fa = async () => {
      await webserviceClient.validate2fa(twoFactorData.mfaKey);
      setTimeout(()=> setShowModal(false), 1200); 
      setTimeout(()=> navigation?.goBack(), 1700);
    }
    const deny2fa = async () => {
      console.log("Abgelehnt für " + twoFactorData.username); 
      setShowModal(false); 
      setTimeout(()=> navigation?.goBack(), 500);
    }
  return (
      <View style={{flex: 1, height: '100%', alignContent: 'center', flexDirection: 'row'}}>
    <Modal isVisible={showModal} ref={data => modalWindow = data}>
      <CustomText style={styles.header} colorScheme={colorScheme}>Loginanfrage für User {twoFactorData.username}</CustomText>
      <CustomText style={styles.header} colorScheme={colorScheme}>{twoFactorData.device} - {twoFactorData.application}</CustomText>
      <CustomText style={styles.header} colorScheme={colorScheme}>Login bestätigen?</CustomText>
      <CustomButtonWithCheckMark onPress={confirm2fa} text="Bestätigen"/>
      <CustomButton onPress={deny2fa} text="Abbrechen"/>
    </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  header:{
    textAlign: 'center',
    fontSize: 20,
    marginVertical: 5
  }
})

export default TwoFactorConfirmationScreen
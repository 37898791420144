import { View, createElement, Platform } from 'react-native'
import React, { useState } from 'react'
import DateTimePicker from '@react-native-community/datetimepicker';
import CustomButton from '../CustomButton';

const CustomDateTimePicker = ({value, onChange, style, colorScheme}: {value: Date, onChange: Function, style: any, colorScheme: "light" | "dark"}) => {
    const ios = Platform.OS === "ios";
    const android = Platform.OS === "android"
    const [pickState, setPickState] = useState(0);
    
  return (
      ios ? 
      <DateTimePicker value={value} onChange={(event, date)=> onChange(date)}
          mode="datetime" style={style} themeVariant={colorScheme}/> :
        android ?
    <View>
        <CustomButton onPress={()=> setPickState(1)} text={value.toLocaleString()} secondary/>
        {pickState === 1 &&
          <DateTimePicker value={value} onChange={(event: any, date?: Date) => { if(date) { onChange(date); setPickState(2); } else {setPickState(0)} }}
            mode="date" style={style} themeVariant={colorScheme} onTouchCancel={()=> setPickState(0)} />}
        {pickState === 2 &&
          <DateTimePicker value={value} onChange={(event: any, date?: Date) => { if(date) { onChange(date); } setPickState(0) }}
            mode="time" style={style} themeVariant={colorScheme} onTouchCancel={()=> setPickState(0)}/>}
    </View>
    :
    createElement('input', {
      type: 'date',
      value: value.toString(),
      onInput: onChange,
    })
  )
}

export default CustomDateTimePicker
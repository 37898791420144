import { Text, StyleSheet } from 'react-native'
import React from 'react'

const CustomText = (props: any) => {
  return (
    <Text style={[props.style, styles.textStyle, (props.colorScheme == 'dark' ? styles.textDark : styles.textLight)]} selectable={props.selectable}>{props.children}</Text>
  )
}

const styles = StyleSheet.create({
    textDark: {
        color: '#f3f3f3',
    },
    textLight: {
        color: '#3a3a3a',
    },
    textStyle:{
    }
});

export default CustomText